import React, { ReactElement } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Theme,
  Typography,
} from "@material-ui/core";
import { LogoAndDescriptionPhrase } from "./LogoAndDescriptionPhrase";
import { Copyright } from "./Copyright";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(3),
  },
  logo: {
    display: "flex",
    justifyContent: "center",
  },
}));

/**
 * 基本レイアウトコンポーネント
 *
 * @param props
 * @constructor
 */
export const BaseLayout: React.FC = (props): ReactElement => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      {/* FIXME: リリース時、テスト中の文言を削除する */}
      <Typography variant={"h5"} align={"center"} color={"error"}>
        (アルファテスト中)
      </Typography>
      <div className={classes.paper}>
        <div className={classes.logo}>
          <LogoAndDescriptionPhrase />
        </div>
        <div className={classes.content}>{props.children}</div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
