import React, { ReactElement } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { TopPageLayout } from "./TopPageLayout";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  list: {
    paddingLeft: 0,
  },
  grid: {
    width: "100%",
    margin: 0,
  },
}));

type Feature = {
  title: string;
  description: string[];
};

const features: Feature[] = [
  {
    title: "シンプルで使いやすい！",
    description: ["操作が簡単！", "誰でも簡単に利用できます。"],
  },
  {
    title: "圧倒的な低コスト",
    description: [
      "無料またはXXX円/月から利用できます。",
      "高額な専用ハードウェアは不要です。",
    ],
  },
];

export const Features: React.FC = (): ReactElement => {
  const classes = useStyles();
  return (
    <TopPageLayout title={"特徴"}>
      <Grid
        className={classes.grid}
        container={true}
        spacing={5}
        justify={"center"}
      >
        {features.map((feature, index) => (
          <Grid item={true} key={index} xs={10} sm={6} md={5} lg={4}>
            <Card>
              <CardHeader
                title={feature.title}
                titleTypographyProps={{ align: "center" }}
                className={classes.cardHeader}
              />
              <CardContent>
                <ul className={classes.list}>
                  {feature.description.map((line) => (
                    <Typography variant={"subtitle1"} key={line}>
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </TopPageLayout>
  );
};
