import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  AnalysisV20Config,
  AnalysisV20ConfigTextEmotion,
  AnalysisV20Result,
  VoiceActivityDetection,
  VoiceEmotion4MergeConfThresholds,
  VoiceEmotion8ConfThresholds,
} from "../../../lib";
import { useDropzone } from "react-dropzone";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  Theme,
  Typography,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  RoutingPaths,
  UserAnalysisPaths,
  UserRoutingPaths,
} from "../../../CommonTypes";
import { VadConfig } from "./VadConfig";
import { Emotion8ConfThresholdConfig } from "./Emotion8ConfThresholdConfig";
import { SuffixIntonationConfig } from "./SuffixIntonationConfig";
import { TextEmotionConfig } from "./TextEmotionConfig";
import { Emotion4MergeConfThresholdConfig } from "./Emotion4MergeConfThresholdConfig";
import { getAudioType, readAudioFileData } from "../../../utils/utility";
import { AnalysisConfigExpansionPanel } from "./AnalysisConfigExpansionPanel";
import { getAnalysisErrorDescription } from "./AnalysisError";
import {
  loadAnalysisConfig,
  loadNotSaveFileNameOption,
  saveAnalysisConfig,
  saveNotSaveFileNameOption,
} from "../../../utils/storage";
import {
  postAnalysis,
  reflectNotSaveFileNameOptionToConfig,
} from "../../../utils/post";

const DivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
`;

const DivContentMargin = styled.div`
  margin-top: 10px;
`;

const DivFileSelectorWrapper = styled.div`
  text-align: center;
`;

const DivMultiChannelFileCaptionWrapper = styled.div`
  margin-top: 20px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainCard: {
      cursor: "pointer",
    },
    errorMessage: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    allConfigResetButton: {
      margin: "20px 0 10px auto",
      display: "block",
    },
    combineChannelsCheckbox: {
      marginLeft: "0",
      marginTop: "0.5em",
    },
    saveFileNameCheckBox: {
      marginLeft: "0",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

/**
 * 解析要求コンポーネントの引数
 */
type AnalysisRequestProps = {
  targetFileSetter: React.Dispatch<File>; // 解析対象音声パスセッター
  responseSetter: React.Dispatch<AnalysisV20Result>; // レスポンス登録セッター
  configSetter: React.Dispatch<AnalysisV20Config>; // 解析設定セッター
  accessToken: string; // アクセストークン
};

/**
 * 解析要求コンポーネント
 *
 * @constructor
 */
export const AnalysisRequest: React.FC<AnalysisRequestProps> = (
  props: AnalysisRequestProps
): ReactElement => {
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [selectingFile, setSelectingFile] = useState<File | null>(null);
  const [audioFileIsMultiChannel, setAudioFileIsMultiChannel] = useState<
    boolean
  >(false);
  const [analysisConfig, setAnalysisConfig] = useState<AnalysisV20Config>(
    loadAnalysisConfig()
  );
  const [notToSaveFileName, setNotToSaveFileName] = useState(
    loadNotSaveFileNameOption()
  );
  const [errorDescription, setErrorDescription] = useState<string>();

  const routeHistory = useHistory();
  const classes = useStyles();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const firstAcceptFile = acceptedFiles[0];
    if (firstAcceptFile) {
      const audioType = getAudioType(firstAcceptFile);
      if (audioType === null) {
        setErrorDescription("対応していないファイル形式です");
      } else {
        setErrorDescription("");
        setSelectingFile(firstAcceptFile);
      }
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  // 音声が複数チャンネルかチェック
  useEffect(() => {
    if (selectingFile !== null) {
      readAudioFileData(selectingFile).then((buffer) => {
        if (buffer.numberOfChannels > 1) {
          setAudioFileIsMultiChannel(true);
        } else {
          setAudioFileIsMultiChannel(false);
        }
      });
    }
  }, [selectingFile]);

  // 解析設定の保存
  useEffect(() => {
    saveAnalysisConfig(analysisConfig);
  }, [analysisConfig]);

  // ファイル名非保存設定の保存
  useEffect(() => {
    saveNotSaveFileNameOption(notToSaveFileName);
  }, [notToSaveFileName]);

  const uploadButtonClickHandler = () => {
    if (selectingFile !== null) {
      // ファイル名保存設定
      let config: AnalysisV20Config;
      const reflectedResult = reflectNotSaveFileNameOptionToConfig(
        analysisConfig,
        notToSaveFileName,
        selectingFile
      );
      if (typeof reflectedResult === "string") {
        setErrorDescription(reflectedResult);
        return;
      } else {
        config = reflectedResult;
      }

      setShowLoadingModal(true);
      props.targetFileSetter(selectingFile);
      postAnalysis(selectingFile, props.accessToken, config)
        .then((response) => {
          setShowLoadingModal(false);
          props.responseSetter(response);
          props.configSetter(analysisConfig);
          routeHistory.push(
            RoutingPaths.userMyPage +
              UserRoutingPaths.analysis +
              UserAnalysisPaths.result
          );
        })
        .catch((e) => {
          setShowLoadingModal(false);
          console.error("[Request Analysis Error]", e);
          if (e.response && e.response.data) {
            console.error("[Request Analysis Error Response]", e.response.data);
            const description = getAnalysisErrorDescription(e.response.data);
            setErrorDescription(description);
          } else {
            setErrorDescription("解析に失敗しました");
          }
        });
    } else {
      alert("解析するファイルを指定してください");
    }
  };

  return (
    <DivContentWrapper>
      {errorDescription && (
        <Typography
          variant={"body1"}
          align={"center"}
          color={"error"}
          className={classes.errorMessage}
        >
          {errorDescription}
        </Typography>
      )}
      <Card {...getRootProps()} className={classes.mainCard}>
        <input {...getInputProps()} />
        <CardContent>
          {selectingFile !== null ? (
            <DivFileSelectorWrapper>
              <Typography variant={"h5"}>{selectingFile.name}</Typography>
              {audioFileIsMultiChannel && (
                <DivMultiChannelFileCaptionWrapper>
                  <Typography color="textSecondary" variant={"caption"}>
                    『各チャンネルの音声を結合する』オプションを有効化することで
                  </Typography>
                  <br />
                  <Typography color="textSecondary" variant={"caption"}>
                    音声をモノラルに変換してから解析します
                  </Typography>
                </DivMultiChannelFileCaptionWrapper>
              )}
            </DivFileSelectorWrapper>
          ) : (
            <DivFileSelectorWrapper>
              <Typography color="textSecondary">
                解析する「音声ファイル」をドラッグアンドドロップ
              </Typography>
              <Typography color="textSecondary">もしくは</Typography>
              <Typography color="textSecondary">
                クリックして「音声ファイル」を選択
              </Typography>
              <br />
              <Typography color="textSecondary" variant={"caption"}>
                ※解析対応ファイルフォーマット
              </Typography>
              <br />
              <Typography color="textSecondary" variant={"caption"}>
                WAV, MP3, Ogg, FLAC, AAC, m4a(aac), MP4ファイル
              </Typography>
              <br />
              <Typography color="textSecondary" variant={"caption"}>
                音声はリニアPCM, 16kHz, 16bitに自動的に変換して解析します
              </Typography>
            </DivFileSelectorWrapper>
          )}
        </CardContent>
      </Card>
      <Button
        className={classes.allConfigResetButton}
        variant={"contained"}
        size={"small"}
        onClick={(e) => {
          e.stopPropagation();
          setAnalysisConfig({});
        }}
      >
        全ての解析設定をリセット
      </Button>
      <AnalysisConfigExpansionPanel
        title={"発話検知設定"}
        isValueChanged={analysisConfig.voiceActivityDetection !== undefined}
        resetCallback={() => {
          delete analysisConfig.voiceActivityDetection;
          setAnalysisConfig({ ...analysisConfig });
        }}
      >
        <VadConfig
          currentConfig={analysisConfig.voiceActivityDetection}
          configSetter={(vadConfig: VoiceActivityDetection) =>
            setAnalysisConfig({
              ...analysisConfig,
              voiceActivityDetection: vadConfig,
            })
          }
        />
      </AnalysisConfigExpansionPanel>
      <AnalysisConfigExpansionPanel
        title={"8感情の確定感情閾値設定"}
        isValueChanged={
          analysisConfig.voiceEmotion?.emotion8ConfThresholds !== undefined
        }
        resetCallback={() => {
          delete analysisConfig.voiceEmotion?.emotion8ConfThresholds;
          setAnalysisConfig({ ...analysisConfig });
        }}
      >
        <Emotion8ConfThresholdConfig
          currentConfig={analysisConfig.voiceEmotion?.emotion8ConfThresholds}
          configSetter={(threshold: VoiceEmotion8ConfThresholds) =>
            setAnalysisConfig({
              ...analysisConfig,
              voiceEmotion: {
                ...analysisConfig.voiceEmotion,
                emotion8ConfThresholds: threshold,
              },
            })
          }
        />
      </AnalysisConfigExpansionPanel>
      <AnalysisConfigExpansionPanel
        title={"4感情の確定感情閾値設定"}
        isValueChanged={
          analysisConfig.voiceEmotion?.emotion4MergeConfThresholds !== undefined
        }
        resetCallback={() => {
          delete analysisConfig.voiceEmotion?.emotion4MergeConfThresholds;
          setAnalysisConfig({ ...analysisConfig });
        }}
      >
        <Emotion4MergeConfThresholdConfig
          currentConfig={
            analysisConfig.voiceEmotion?.emotion4MergeConfThresholds
          }
          configSetter={(threshold: VoiceEmotion4MergeConfThresholds) =>
            setAnalysisConfig({
              ...analysisConfig,
              voiceEmotion: {
                ...analysisConfig.voiceEmotion,
                emotion4MergeConfThresholds: threshold,
              },
            })
          }
        />
      </AnalysisConfigExpansionPanel>
      <AnalysisConfigExpansionPanel
        title={"語尾抑揚設定"}
        isValueChanged={
          analysisConfig.voiceEmotion?.suffixIntonationThreshold !== undefined
        }
        resetCallback={() => {
          delete analysisConfig.voiceEmotion?.suffixIntonationThreshold;
          setAnalysisConfig({ ...analysisConfig });
        }}
      >
        <SuffixIntonationConfig
          currentThreshold={
            analysisConfig.voiceEmotion?.suffixIntonationThreshold
          }
          thresholdSetter={(threshold: number) =>
            setAnalysisConfig({
              ...analysisConfig,
              voiceEmotion: {
                ...analysisConfig.voiceEmotion,
                suffixIntonationThreshold: threshold,
              },
            })
          }
        />
      </AnalysisConfigExpansionPanel>
      <AnalysisConfigExpansionPanel
        title={"文字感情解析設定"}
        isValueChanged={analysisConfig.textEmotion !== undefined}
        resetCallback={() => {
          delete analysisConfig.textEmotion;
          setAnalysisConfig({ ...analysisConfig });
        }}
      >
        <TextEmotionConfig
          currentTextEmotionConfig={analysisConfig.textEmotion}
          setTextEmotionConfig={(config: AnalysisV20ConfigTextEmotion) =>
            setAnalysisConfig({
              ...analysisConfig,
              textEmotion: config,
            })
          }
        />
      </AnalysisConfigExpansionPanel>
      <FormControlLabel
        className={classes.combineChannelsCheckbox}
        control={
          <Checkbox
            checked={
              analysisConfig.combineChannels === undefined
                ? false
                : analysisConfig.combineChannels
            }
            color={"primary"}
            onChange={(e) =>
              setAnalysisConfig({
                ...analysisConfig,
                combineChannels: e.target.checked,
              })
            }
          />
        }
        label={"各チャンネルの音声を結合する"}
      />
      <FormControlLabel
        className={classes.saveFileNameCheckBox}
        control={
          <Checkbox
            checked={notToSaveFileName}
            color={"primary"}
            onChange={(e) => setNotToSaveFileName(e.target.checked)}
          />
        }
        label={"ファイル名を保存しない"}
      />
      <DivContentMargin />
      <Button
        variant="contained"
        color="secondary"
        fullWidth={true}
        startIcon={<CloudUpload />}
        disabled={selectingFile === null}
        onClick={uploadButtonClickHandler}
      >
        解析
      </Button>
      <Backdrop
        className={classes.backdrop}
        open={showLoadingModal}
        onClick={(e) => e.preventDefault()}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DivContentWrapper>
  );
};
