/* tslint:disable */
/* eslint-disable */
/**
 * ST Cloud Voice Analysis
 * 音声の感情解析を行う
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support_st@agi-web.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 * 各種解析設定
 * @export
 * @interface AnalysisV12Config
 */
export interface AnalysisV12Config {
  /**
   *
   * @type {VoiceActivityDetection}
   * @memberof AnalysisV12Config
   */
  voiceActivityDetection?: VoiceActivityDetection;
  /**
   *
   * @type {VoiceEmotion}
   * @memberof AnalysisV12Config
   */
  voiceEmotion?: VoiceEmotion;
  /**
   * 入力音声のオリジナル名
   * @type {string}
   * @memberof AnalysisV12Config
   */
  originalFileName?: string;
  /**
   * チャンネルを合成してモノラルに変換してから解析を行う
   * @type {boolean}
   * @memberof AnalysisV12Config
   */
  combineChannels?: boolean;
}
/**
 *
 * @export
 * @interface AnalysisV12Request
 */
export interface AnalysisV12Request {
  /**
   *
   * @type {AudioInfo}
   * @memberof AnalysisV12Request
   */
  audio: AudioInfo;
  /**
   *
   * @type {AnalysisV12Config}
   * @memberof AnalysisV12Request
   */
  config?: AnalysisV12Config;
}
/**
 * 解析結果オブジェクト
 * @export
 * @interface AnalysisV12Result
 */
export interface AnalysisV12Result {
  /**
   * 解析に使用したSTエンジンのバージョン
   * @type {string}
   * @memberof AnalysisV12Result
   */
  stVersion: string;
  /**
   * 音声チャンネル数
   * @type {number}
   * @memberof AnalysisV12Result
   */
  channelCount: number;
  /**
   * チャンネル毎の発話数
   * @type {Array<number>}
   * @memberof AnalysisV12Result
   */
  utteranceCountList: Array<number>;
  /**
   * 発話毎の解析結果
   * @type {Array<AnalysisV12ResultUtteranceList>}
   * @memberof AnalysisV12Result
   */
  utteranceList: Array<AnalysisV12ResultUtteranceList>;
}
/**
 *
 * @export
 * @interface AnalysisV12ResultUtteranceList
 */
export interface AnalysisV12ResultUtteranceList {
  /**
   * 音声チャンネル番号  * モノラル: 0 * ステレオ: 1 or 2
   * @type {number}
   * @memberof AnalysisV12ResultUtteranceList
   */
  channelId: number;
  /**
   * 発話番号（チャンネル毎）
   * @type {number}
   * @memberof AnalysisV12ResultUtteranceList
   */
  utteranceId: number;
  /**
   * 発話開始時間（ミリ秒）
   * @type {number}
   * @memberof AnalysisV12ResultUtteranceList
   */
  startMilliSecond: number;
  /**
   * 発話終了時間（ミリ秒）
   * @type {number}
   * @memberof AnalysisV12ResultUtteranceList
   */
  endMilliSecond: number;
  /**
   *
   * @type {VoiceEmotionResult}
   * @memberof AnalysisV12ResultUtteranceList
   */
  voiceEmotion: VoiceEmotionResult;
}
/**
 * 各種解析設定
 * @export
 * @interface AnalysisV20Config
 */
export interface AnalysisV20Config {
  /**
   *
   * @type {VoiceActivityDetection}
   * @memberof AnalysisV20Config
   */
  voiceActivityDetection?: VoiceActivityDetection;
  /**
   *
   * @type {VoiceEmotion}
   * @memberof AnalysisV20Config
   */
  voiceEmotion?: VoiceEmotion;
  /**
   *
   * @type {AnalysisV20ConfigTextEmotion}
   * @memberof AnalysisV20Config
   */
  textEmotion?: AnalysisV20ConfigTextEmotion;
  /**
   * 入力音声のオリジナル名
   * @type {string}
   * @memberof AnalysisV20Config
   */
  originalFileName?: string;
  /**
   * チャンネルを合成してモノラルに変換してから解析を行う
   * @type {boolean}
   * @memberof AnalysisV20Config
   */
  combineChannels?: boolean;
}
/**
 * 音声認識についての設定
 * @export
 * @interface AnalysisV20ConfigTextEmotion
 */
export interface AnalysisV20ConfigTextEmotion {
  /**
   * 音声認識の種別  | 種別 | 説明 |  |---|---| | google | Google Cloud Speech-to-Textを使用して音声認識 | | text | 音声認識は使用せず、文字情報を\"transcriptionList\"に入力する | | disable | 音声認識を無効化 |
   * @type {string}
   * @memberof AnalysisV20ConfigTextEmotion
   */
  speechRecognitionType?: AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum;
  /**
   * チャンネルごとの発話単位の書き起こし文。speechRecognitionTypeを\"text\"に設定した場合に使用する
   * @type {Array<Array<string>>}
   * @memberof AnalysisV20ConfigTextEmotion
   */
  transcriptionList?: Array<Array<string>>;
}

/**
 * @export
 * @enum {string}
 */
export enum AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum {
  Google = "google",
  Text = "text",
  Disable = "disable",
}

/**
 *
 * @export
 * @interface AnalysisV20Request
 */
export interface AnalysisV20Request {
  /**
   *
   * @type {AudioInfo}
   * @memberof AnalysisV20Request
   */
  audio: AudioInfo;
  /**
   *
   * @type {AnalysisV20Config}
   * @memberof AnalysisV20Request
   */
  config?: AnalysisV20Config;
}
/**
 * 解析結果オブジェクト
 * @export
 * @interface AnalysisV20Result
 */
export interface AnalysisV20Result {
  /**
   * 解析に使用したSTエンジンのバージョン
   * @type {string}
   * @memberof AnalysisV20Result
   */
  stVersion: string;
  /**
   * 音声チャンネル数
   * @type {number}
   * @memberof AnalysisV20Result
   */
  channelCount: number;
  /**
   * チャンネル毎の発話数
   * @type {Array<number>}
   * @memberof AnalysisV20Result
   */
  utteranceCountList: Array<number>;
  /**
   * 発話毎の解析結果
   * @type {Array<AnalysisV20ResultUtteranceList>}
   * @memberof AnalysisV20Result
   */
  utteranceList: Array<AnalysisV20ResultUtteranceList>;
}
/**
 *
 * @export
 * @interface AnalysisV20ResultUtteranceList
 */
export interface AnalysisV20ResultUtteranceList {
  /**
   * 音声チャンネル番号  * モノラル: 0 * ステレオ: 1 or 2
   * @type {number}
   * @memberof AnalysisV20ResultUtteranceList
   */
  channelId: number;
  /**
   * 発話番号（チャンネル毎）
   * @type {number}
   * @memberof AnalysisV20ResultUtteranceList
   */
  utteranceId: number;
  /**
   * 発話開始時間（ミリ秒）
   * @type {number}
   * @memberof AnalysisV20ResultUtteranceList
   */
  startMilliSecond: number;
  /**
   * 発話終了時間（ミリ秒）
   * @type {number}
   * @memberof AnalysisV20ResultUtteranceList
   */
  endMilliSecond: number;
  /**
   *
   * @type {VoiceEmotionResult}
   * @memberof AnalysisV20ResultUtteranceList
   */
  voiceEmotion: VoiceEmotionResult;
  /**
   *
   * @type {TextEmotionResult}
   * @memberof AnalysisV20ResultUtteranceList
   */
  textEmotion?: TextEmotionResult;
}
/**
 * 音声に関する情報
 * @export
 * @interface AudioInfo
 */
export interface AudioInfo {
  /**
   * 音声データ種別   | 種別 | 説明 |  |---|---| | wav | wav形式の音声。16kHz, 16bitを推奨 | | mp3 | mp3形式の音声 | | ogg | ogg形式の音声 | | flac | flac形式の音声 | | aac | aac形式の音声 | | m4a | m4a(aac)形式の音声 | | mp4 | mp4形式のデータ | | mov | mov形式のデータ |
   * @type {string}
   * @memberof AudioInfo
   */
  audioDataType: AudioInfoAudioDataTypeEnum;
  /**
   * base64エンコードした音声ファイルデータ
   * @type {string}
   * @memberof AudioInfo
   */
  audioData: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AudioInfoAudioDataTypeEnum {
  Wav = "wav",
  Mp3 = "mp3",
  Ogg = "ogg",
  Flac = "flac",
  Aac = "aac",
  M4a = "m4a",
  Mp4 = "mp4",
  Mov = "mov",
}

/**
 * エラーレスポンスの内容
 * @export
 * @interface HttpError
 */
export interface HttpError {
  /**
   * エラーコード
   * @type {number}
   * @memberof HttpError
   */
  errorCode: number;
  /**
   * エラーの詳細
   * @type {string}
   * @memberof HttpError
   */
  detail: string;
}
/**
 * 文字感情解析の結果
 * @export
 * @interface TextEmotionResult
 */
export interface TextEmotionResult {
  /**
   * 音声認識の書き起こし文
   * @type {string}
   * @memberof TextEmotionResult
   */
  transcription: string;
  /**
   * 書き起こし文の読み仮名
   * @type {string}
   * @memberof TextEmotionResult
   */
  pronunciation: string;
  /**
   * 文字感情
   * @type {Array<TextEmotionResultEmotionFeatures>}
   * @memberof TextEmotionResult
   */
  emotionFeatures: Array<TextEmotionResultEmotionFeatures>;
  /**
   * 文章の意味
   * @type {Array<TextEmotionResultSemanticFeatures>}
   * @memberof TextEmotionResult
   */
  semanticFeatures: Array<TextEmotionResultSemanticFeatures>;
  /**
   * 形態素解析結果
   * @type {Array<TextEmotionResultWordFeatures>}
   * @memberof TextEmotionResult
   */
  wordFeatures: Array<TextEmotionResultWordFeatures>;
  /**
   * Google Cloud Speech-to-Textの単語ごとの音声認識結果
   * @type {Array<TextEmotionResultWordsByGoogleStt>}
   * @memberof TextEmotionResult
   */
  wordsByGoogleStt?: Array<TextEmotionResultWordsByGoogleStt>;
  /**
   * 終了コード  | コード | 内容 |  |---|---| | 0 | 正常終了 | | 1 | 発話検知無効化時の最大音声長超過 | | 2 | 音声認識に失敗した | | 3 | 音声認識で書き起こし文が取得されなかった | | 10 | 無償プランの解析制限を超過した | | 99 | 内部エラー |
   * @type {number}
   * @memberof TextEmotionResult
   */
  finishCode: number;
}
/**
 *
 * @export
 * @interface TextEmotionResultEmotionFeatures
 */
export interface TextEmotionResultEmotionFeatures {
  /**
   * 感情種別
   * @type {string}
   * @memberof TextEmotionResultEmotionFeatures
   */
  emotionType: string;
  /**
   * 感情ワード
   * @type {string}
   * @memberof TextEmotionResultEmotionFeatures
   */
  emotionWord: string;
}
/**
 *
 * @export
 * @interface TextEmotionResultSemanticFeatures
 */
export interface TextEmotionResultSemanticFeatures {
  /**
   * 意味種別
   * @type {string}
   * @memberof TextEmotionResultSemanticFeatures
   */
  type: string;
  /**
   * 対象の言葉
   * @type {string}
   * @memberof TextEmotionResultSemanticFeatures
   */
  value: string;
}
/**
 *
 * @export
 * @interface TextEmotionResultWordFeatures
 */
export interface TextEmotionResultWordFeatures {
  /**
   * 表層形
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  surface: string;
  /**
   * 品詞細分類1
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  pos1: string;
  /**
   * 品詞細分類2
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  pos2: string;
  /**
   * 品詞細分類3
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  pos3: string;
  /**
   * 品詞細分類4
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  pos4: string;
  /**
   * 活用形
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  cForm: string;
  /**
   * 活用形
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  lForm: string;
  /**
   * 原形
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  lemma: string;
  /**
   * 読み
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  pronBase: string;
  /**
   * 発音
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  pron: string;
  /**
   * 感情種別
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  emotion: string;
  /**
   * 感情種別のID
   * @type {string}
   * @memberof TextEmotionResultWordFeatures
   */
  emotionId: string;
}
/**
 *
 * @export
 * @interface TextEmotionResultWordsByGoogleStt
 */
export interface TextEmotionResultWordsByGoogleStt {
  /**
   * 単語の開始時間の参考値（\"3.5s\"形式）
   * @type {string}
   * @memberof TextEmotionResultWordsByGoogleStt
   */
  startTime: string;
  /**
   * 単語の終了時間の参考値（\"3.5s\"形式）
   * @type {string}
   * @memberof TextEmotionResultWordsByGoogleStt
   */
  endTime: string;
  /**
   * 単語
   * @type {string}
   * @memberof TextEmotionResultWordsByGoogleStt
   */
  word: string;
}
/**
 * 発話検知に用いる設定値
 * @export
 * @interface VoiceActivityDetection
 */
export interface VoiceActivityDetection {
  /**
   * 発話検知区間長（ミリ秒）
   * @type {number}
   * @memberof VoiceActivityDetection
   */
  startSpan?: number;
  /**
   * 発話終了判定区間長（ミリ秒）
   * @type {number}
   * @memberof VoiceActivityDetection
   */
  endSpan?: number;
  /**
   * 発話検知ボリュームの閾値  単位: 16bit表現の振幅値
   * @type {number}
   * @memberof VoiceActivityDetection
   */
  detectVolume?: number;
  /**
   * 1発話の最大長（ミリ秒）  startSpanとendSpanの合計値以上の値であること
   * @type {number}
   * @memberof VoiceActivityDetection
   */
  maxTime?: number;
  /**
   * 発話検知巻き戻し時間（ミリ秒）
   * @type {number}
   * @memberof VoiceActivityDetection
   */
  startMargin?: number;
  /**
   * 発話最大時間時末尾処理設定アルゴリズム  | 種別 | 説明 |  |---|---| | none | 無効 | | cut-in-longest-unvoiced | 無声フレーム最長期間でカット |
   * @type {string}
   * @memberof VoiceActivityDetection
   */
  maxCutMethod?: VoiceActivityDetectionMaxCutMethodEnum;
  /**
   * 発話検知を無効化する。発話検知無効化時の最大音声長は15秒。
   * @type {boolean}
   * @memberof VoiceActivityDetection
   */
  disable?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum VoiceActivityDetectionMaxCutMethodEnum {
  None = "none",
  CutInLongestUnvoiced = "cut-in-longest-unvoiced",
}

/**
 * 音声感情解析の各種変更
 * @export
 * @interface VoiceEmotion
 */
export interface VoiceEmotion {
  /**
   *
   * @type {VoiceEmotion8ConfThresholds}
   * @memberof VoiceEmotion
   */
  emotion8ConfThresholds?: VoiceEmotion8ConfThresholds;
  /**
   *
   * @type {VoiceEmotion4MergeConfThresholds}
   * @memberof VoiceEmotion
   */
  emotion4MergeConfThresholds?: VoiceEmotion4MergeConfThresholds;
  /**
   * 語尾抑揚の閾値
   * @type {number}
   * @memberof VoiceEmotion
   */
  suffixIntonationThreshold?: number;
  /**
   * 感情レベルを計算する際のSoftmax温度係数（正の実数）
   * @type {number}
   * @memberof VoiceEmotion
   */
  softmaxTemperature?: number;
}
/**
 * 音声感情解析の4感情の確定感情閾値  8感情の確定感情が不明の際、各感情レベルを4感情で足し合わせた値に本項目の閾値を反映し、4感情の確定感情を判定する
 * @export
 * @interface VoiceEmotion4MergeConfThresholds
 */
export interface VoiceEmotion4MergeConfThresholds {
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion4MergeConfThresholds
   */
  joy?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion4MergeConfThresholds
   */
  anger?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion4MergeConfThresholds
   */
  sorrow?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion4MergeConfThresholds
   */
  calm?: number;
}
/**
 * 音声感情解析の8感情の確定感情閾値
 * @export
 * @interface VoiceEmotion8ConfThresholds
 */
export interface VoiceEmotion8ConfThresholds {
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion8ConfThresholds
   */
  joy1?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion8ConfThresholds
   */
  joy2?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion8ConfThresholds
   */
  anger1?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion8ConfThresholds
   */
  anger2?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion8ConfThresholds
   */
  anger3?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion8ConfThresholds
   */
  sorrow1?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion8ConfThresholds
   */
  sorrow2?: number;
  /**
   *
   * @type {number}
   * @memberof VoiceEmotion8ConfThresholds
   */
  calm?: number;
}

/**
 * 音声感情解析の結果
 * @export
 * @interface VoiceEmotionResult
 */
export interface VoiceEmotionResult {
  /**
   * [8感情分類](#section/8)での第一感情
   * @type {string}
   * @memberof VoiceEmotionResult
   */
  emotion8: VoiceEmotionResultEmotion8Enum;
  /**
   * 第一感情レベル
   * @type {number}
   * @memberof VoiceEmotionResult
   */
  emotion8Level: number;
  /**
   * [8感情分類](#section/8)での確定第一感情
   * @type {string}
   * @memberof VoiceEmotionResult
   */
  emotion8Conf: VoiceEmotionResultEmotion8ConfEnum;
  /**
   *
   * @type {VoiceEmotionResultEmotion8LevelDetail}
   * @memberof VoiceEmotionResult
   */
  emotion8LevelDetail: VoiceEmotionResultEmotion8LevelDetail;
  /**
   * [4感情分類](#section/4)での第一感情
   * @type {string}
   * @memberof VoiceEmotionResult
   */
  emotion4: VoiceEmotionResultEmotion4Enum;
  /**
   * [4感情分類](#section/4)での確定第一感情
   * @type {string}
   * @memberof VoiceEmotionResult
   */
  emotion4Conf: VoiceEmotionResultEmotion4ConfEnum;
  /**
   * [ポジティブ・ネガティブ・ニュートラル分類](#section/PNN)
   * @type {string}
   * @memberof VoiceEmotionResult
   */
  posiNegaNeu: VoiceEmotionResultPosiNegaNeuEnum;
  /**
   * 語尾抑揚種別  | 種別 | 内容 |  |---|---| | up | 語尾が上がっている | | not-up | 語尾が上がっていない | | fail | エラー |
   * @type {string}
   * @memberof VoiceEmotionResult
   */
  suffixIntonation: VoiceEmotionResultSuffixIntonationEnum;
  /**
   * 語尾抑揚の傾き。エラー時は-99999.0を返す。
   * @type {number}
   * @memberof VoiceEmotionResult
   */
  suffixIntonationSlope: number;
  /**
   * 発話ごとの音声解析終了コード  | コード | 内容 |  |---|---| | 0 | 正常終了 | | 1 | 発話検知無効化時の最大音声長超過 | | 2 | 発話が短すぎるため解析失敗 | | 3 | 発話が長すぎるため解析失敗 | | 4 | 声が入っていない | | 5 | 声ではない | | 10 | 無償プランの解析制限を超過した | | 99 | 内部エラー |
   * @type {number}
   * @memberof VoiceEmotionResult
   */
  finishCode: number;
}

/**
 * @export
 * @enum {string}
 */
export enum VoiceEmotionResultEmotion8Enum {
  Joy1 = "joy1",
  Joy2 = "joy2",
  Anger1 = "anger1",
  Anger2 = "anger2",
  Anger3 = "anger3",
  Sorrow1 = "sorrow1",
  Sorrow2 = "sorrow2",
  Calm = "calm",
  SameLevel = "same-level",
  LowLevel = "low-level",
  LongUtterance = "long-utterance",
  Fail = "fail",
}
/**
 * @export
 * @enum {string}
 */
export enum VoiceEmotionResultEmotion8ConfEnum {
  Joy1 = "joy1",
  Joy2 = "joy2",
  Anger1 = "anger1",
  Anger2 = "anger2",
  Anger3 = "anger3",
  Sorrow1 = "sorrow1",
  Sorrow2 = "sorrow2",
  Calm = "calm",
  SameLevel = "same-level",
  LowLevel = "low-level",
  LongUtterance = "long-utterance",
  Fail = "fail",
}
/**
 * @export
 * @enum {string}
 */
export enum VoiceEmotionResultEmotion4Enum {
  Joy = "joy",
  Anger = "anger",
  Sorrow = "sorrow",
  Calm = "calm",
  SameLevel = "same-level",
  LowLevel = "low-level",
  LongUtterance = "long-utterance",
  SameMergedLevel = "same-merged-level",
  Fail = "fail",
}
/**
 * @export
 * @enum {string}
 */
export enum VoiceEmotionResultEmotion4ConfEnum {
  Joy = "joy",
  Anger = "anger",
  Sorrow = "sorrow",
  Calm = "calm",
  SameLevel = "same-level",
  LowLevel = "low-level",
  LongUtterance = "long-utterance",
  SameMergedLevel = "same-merged-level",
  Fail = "fail",
}
/**
 * @export
 * @enum {string}
 */
export enum VoiceEmotionResultPosiNegaNeuEnum {
  Positive = "positive",
  Negative = "negative",
  Neutral = "neutral",
  SameLevel = "same-level",
  LowLevel = "low-level",
  LongUtterance = "long-utterance",
  Fail = "fail",
}
/**
 * @export
 * @enum {string}
 */
export enum VoiceEmotionResultSuffixIntonationEnum {
  Up = "up",
  NotUp = "not-up",
  Fail = "fail",
}

/**
 * 各感情レベル 各感情レベルを合計すると100になります
 * @export
 * @interface VoiceEmotionResultEmotion8LevelDetail
 */
export interface VoiceEmotionResultEmotion8LevelDetail {
  /**
   * 喜び1感情レベル
   * @type {number}
   * @memberof VoiceEmotionResultEmotion8LevelDetail
   */
  joy1Level: number;
  /**
   * 喜び2感情レベル
   * @type {number}
   * @memberof VoiceEmotionResultEmotion8LevelDetail
   */
  joy2Level: number;
  /**
   * 怒り1感情レベル
   * @type {number}
   * @memberof VoiceEmotionResultEmotion8LevelDetail
   */
  anger1Level: number;
  /**
   * 怒り2感情レベル
   * @type {number}
   * @memberof VoiceEmotionResultEmotion8LevelDetail
   */
  anger2Level: number;
  /**
   * 怒り3感情レベル
   * @type {number}
   * @memberof VoiceEmotionResultEmotion8LevelDetail
   */
  anger3Level: number;
  /**
   * 哀しみ1感情レベル
   * @type {number}
   * @memberof VoiceEmotionResultEmotion8LevelDetail
   */
  sorrow1Level: number;
  /**
   * 哀しみ2感情レベル
   * @type {number}
   * @memberof VoiceEmotionResultEmotion8LevelDetail
   */
  sorrow2Level: number;
  /**
   * 平常感情レベル
   * @type {number}
   * @memberof VoiceEmotionResultEmotion8LevelDetail
   */
  calmLevel: number;
}

/**
 * AnalysisApi - axios parameter creator
 * @export
 */
export const AnalysisApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * アップロードした音声について同期処理で解析を行います。リクエストペイロードの最大サイズは10MBです。
     * @summary 同期音声解析(Ver.1.2)
     * @param {AnalysisV12Request} [analysisV12Request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stCloudAnalysisV12Sync: async (
      analysisV12Request?: AnalysisV12Request,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/st-cloud/v1.2/analysis/sync`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        analysisV12Request,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * アップロードした音声について同期処理で解析を行います。リクエストペイロードの最大サイズは10MBです。
     * @summary 同期音声解析(Ver.2.0)
     * @param {AnalysisV20Request} [analysisV20Request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stCloudAnalysisV20Sync: async (
      analysisV20Request?: AnalysisV20Request,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/st-cloud/v2.0/analysis/sync`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        analysisV20Request,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AnalysisApi - functional programming interface
 * @export
 */
export const AnalysisApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AnalysisApiAxiosParamCreator(configuration);
  return {
    /**
     * アップロードした音声について同期処理で解析を行います。リクエストペイロードの最大サイズは10MBです。
     * @summary 同期音声解析(Ver.1.2)
     * @param {AnalysisV12Request} [analysisV12Request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stCloudAnalysisV12Sync(
      analysisV12Request?: AnalysisV12Request,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AnalysisV12Result>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stCloudAnalysisV12Sync(
        analysisV12Request,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * アップロードした音声について同期処理で解析を行います。リクエストペイロードの最大サイズは10MBです。
     * @summary 同期音声解析(Ver.2.0)
     * @param {AnalysisV20Request} [analysisV20Request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stCloudAnalysisV20Sync(
      analysisV20Request?: AnalysisV20Request,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AnalysisV20Result>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stCloudAnalysisV20Sync(
        analysisV20Request,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AnalysisApi - factory interface
 * @export
 */
export const AnalysisApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AnalysisApiFp(configuration);
  return {
    /**
     * アップロードした音声について同期処理で解析を行います。リクエストペイロードの最大サイズは10MBです。
     * @summary 同期音声解析(Ver.1.2)
     * @param {AnalysisV12Request} [analysisV12Request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stCloudAnalysisV12Sync(
      analysisV12Request?: AnalysisV12Request,
      options?: any
    ): AxiosPromise<AnalysisV12Result> {
      return localVarFp
        .stCloudAnalysisV12Sync(analysisV12Request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * アップロードした音声について同期処理で解析を行います。リクエストペイロードの最大サイズは10MBです。
     * @summary 同期音声解析(Ver.2.0)
     * @param {AnalysisV20Request} [analysisV20Request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stCloudAnalysisV20Sync(
      analysisV20Request?: AnalysisV20Request,
      options?: any
    ): AxiosPromise<AnalysisV20Result> {
      return localVarFp
        .stCloudAnalysisV20Sync(analysisV20Request, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AnalysisApi - object-oriented interface
 * @export
 * @class AnalysisApi
 * @extends {BaseAPI}
 */
export class AnalysisApi extends BaseAPI {
  /**
   * アップロードした音声について同期処理で解析を行います。リクエストペイロードの最大サイズは10MBです。
   * @summary 同期音声解析(Ver.1.2)
   * @param {AnalysisV12Request} [analysisV12Request]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public stCloudAnalysisV12Sync(
    analysisV12Request?: AnalysisV12Request,
    options?: any
  ) {
    return AnalysisApiFp(this.configuration)
      .stCloudAnalysisV12Sync(analysisV12Request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * アップロードした音声について同期処理で解析を行います。リクエストペイロードの最大サイズは10MBです。
   * @summary 同期音声解析(Ver.2.0)
   * @param {AnalysisV20Request} [analysisV20Request]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public stCloudAnalysisV20Sync(
    analysisV20Request?: AnalysisV20Request,
    options?: any
  ) {
    return AnalysisApiFp(this.configuration)
      .stCloudAnalysisV20Sync(analysisV20Request, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * サーバのヘルスチェック用パス
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealth: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
  return {
    /**
     * サーバのヘルスチェック用パス
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHealth(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HealthApiFp(configuration);
  return {
    /**
     * サーバのヘルスチェック用パス
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealth(options?: any): AxiosPromise<string> {
      return localVarFp
        .getHealth(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
  /**
   * サーバのヘルスチェック用パス
   * @summary ヘルスチェック
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public getHealth(options?: any) {
    return HealthApiFp(this.configuration)
      .getHealth(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
