import React, { ReactElement, useEffect, useState } from "react";
import {
  VoiceActivityDetection,
  VoiceActivityDetectionMaxCutMethodEnum,
} from "../../../lib";
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      margin: theme.spacing(1),
      width: "25ch",
    },
    checkbox: {
      margin: theme.spacing(1),
    },
    select: {
      margin: theme.spacing(1),
      width: "25ch",
    },
  })
);

type VadConfigItemProps = {
  label: string; // 表示ラベル
  min: number; // 最小値
  max: number; // 最大値
  step: number; // スピナーのステップ
  value?: number; // 現在の設定値
  default: number; // デフォルト値
  disabled: boolean; // 無効化
  unit: string; // 数値の単位
  newValueSetter: (val: number) => void; // 新しい値のハンドラー
};

/**
 * 発話検知関連設定アイテムコンポーネント
 *
 * @param props
 * @constructor
 */
const VadNumberConfigItem: React.FC<VadConfigItemProps> = (
  props: VadConfigItemProps
) => {
  const classes = useStyles();

  const currentValue = props.value !== undefined ? props.value : props.default;
  const [tmpValue, setTmpValue] = useState<number>(currentValue);
  const [error, setError] = useState(false);
  useEffect(() => setTmpValue(currentValue), [currentValue]);

  const title = (
    <React.Fragment>
      最小値: {props.min}
      <br />
      最大値: {props.max}
      <br />
      デフォルト値: {props.default}
    </React.Fragment>
  );

  return (
    <Tooltip title={title}>
      <TextField
        className={classes.text}
        type={"number"}
        label={props.label}
        inputProps={{ step: props.step }}
        value={tmpValue}
        disabled={props.disabled}
        error={error}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{props.unit}</InputAdornment>
          ),
          inputProps: {
            style: {
              textAlign: "center",
            },
          },
        }}
        onChange={(event) => {
          const newVal = Number(event.target.value);
          if (newVal < props.min || newVal > props.max) {
            setError(true);
          } else {
            setError(false);
          }
          setTmpValue(newVal);
        }}
        onBlur={() => {
          if (tmpValue !== undefined) {
            let newVal = tmpValue;
            if (newVal < props.min) {
              newVal = props.min;
            } else if (newVal > props.max) {
              newVal = props.max;
            }
            props.newValueSetter(newVal);
            setTmpValue(newVal);
            setError(false);
          }
        }}
      />
    </Tooltip>
  );
};

type AnalysisRequestConfigProps = {
  currentConfig: VoiceActivityDetection | undefined; // 現在の解析決定
  configSetter: (vadConfig: VoiceActivityDetection) => void; // 設定変更コールバック
};

/**
 * 発話検知設定一覧コンポーネント
 *
 * @param props
 * @constructor
 */
export const VadConfig: React.FC<AnalysisRequestConfigProps> = (
  props: AnalysisRequestConfigProps
): ReactElement => {
  const classes = useStyles();

  const disabled =
    props.currentConfig !== undefined &&
    props.currentConfig.disable !== undefined
      ? props.currentConfig.disable
      : false;
  return (
    <div>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.configSetter({
                ...props.currentConfig,
                disable: event.target.checked,
              });
            }}
            name={"無効化"}
            color={"primary"}
            inputProps={{
              // @ts-ignore
              "data-testid": "check-disable-speech-recognition",
            }}
          />
        }
        label="無効化"
      />
      <br />
      <VadNumberConfigItem
        label={"発話検知区間長"}
        min={10}
        max={2000}
        step={10}
        value={props.currentConfig?.startSpan}
        default={150}
        disabled={disabled}
        unit={"ミリ秒"}
        newValueSetter={(val: number) => {
          props.configSetter({
            ...props.currentConfig,
            startSpan: val,
          });
        }}
      />
      <VadNumberConfigItem
        label={"発話終了判定区間長"}
        min={10}
        max={2000}
        step={10}
        value={props.currentConfig?.endSpan}
        default={200}
        disabled={disabled}
        unit={"ミリ秒"}
        newValueSetter={(val: number) => {
          props.configSetter({
            ...props.currentConfig,
            endSpan: val,
          });
        }}
      />
      <VadNumberConfigItem
        label={"発話検知ボリューム"}
        min={1}
        max={32000}
        step={10}
        value={props.currentConfig?.detectVolume}
        default={300}
        disabled={disabled}
        unit={"振幅値"}
        newValueSetter={(val: number) => {
          props.configSetter({
            ...props.currentConfig,
            detectVolume: val,
          });
        }}
      />
      <VadNumberConfigItem
        label={"1発話の最大長"}
        min={1000}
        max={300000}
        step={100}
        value={props.currentConfig?.maxTime}
        default={5000}
        disabled={disabled}
        unit={"ミリ秒"}
        newValueSetter={(val: number) => {
          props.configSetter({
            ...props.currentConfig,
            maxTime: val,
          });
        }}
      />
      <VadNumberConfigItem
        label={"発話検知巻き戻し時間"}
        min={0}
        max={2000}
        step={10}
        value={props.currentConfig?.startMargin}
        default={100}
        disabled={disabled}
        unit={"ミリ秒"}
        newValueSetter={(val: number) => {
          props.configSetter({
            ...props.currentConfig,
            startMargin: val,
          });
        }}
      />
      <FormControl className={classes.select} disabled={disabled}>
        <InputLabel>発話最大時間時末尾処理</InputLabel>
        <Select
          value={
            props.currentConfig && props.currentConfig.maxCutMethod
              ? props.currentConfig.maxCutMethod
              : VoiceActivityDetectionMaxCutMethodEnum.None
          }
          inputProps={{
            "data-testid": "select-max-cut-method-type",
          }}
          onChange={(event) => {
            const newType = event.target
              .value as VoiceActivityDetectionMaxCutMethodEnum;
            props.configSetter({
              ...props.currentConfig,
              maxCutMethod: newType,
            });
          }}
        >
          <MenuItem value={VoiceActivityDetectionMaxCutMethodEnum.None}>
            無効
          </MenuItem>
          <MenuItem
            value={VoiceActivityDetectionMaxCutMethodEnum.CutInLongestUnvoiced}
          >
            無声フレーム最長期間
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
