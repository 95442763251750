import React, { ReactElement } from "react";
import {
  RoutingPaths,
  UserAnalysisPaths,
  UserManagementPaths,
  UserRoutingPaths,
} from "../../CommonTypes";
import {
  Breadcrumbs,
  Link as MuiLink,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Link } from "react-router-dom";

/**
 * ユーザページのルーティングからパンくずに表示する名称を取得する
 *
 * @param routing
 */
const getLinkNameFromUserRouting = (routing: UserRoutingPaths): string => {
  switch (routing) {
    case UserRoutingPaths.analysis:
      return "解析";
    case UserRoutingPaths.dashboard:
      return "ダッシュボード";
    case UserRoutingPaths.management:
      return "ユーザ管理";
    case UserRoutingPaths.documents:
      return "ドキュメント";
    case UserRoutingPaths.logout:
      return "ログアウト";
  }
};

/**
 * サブルーティングからパンくずに表示する名称を取得する
 *
 * @param routing
 */
const getLinkNameFromManagementPath = (
  routing: UserManagementPaths | UserAnalysisPaths
): string => {
  switch (routing) {
    case UserAnalysisPaths.result:
      return "結果";
    case UserManagementPaths.changePassword:
      return "パスワード変更";
  }
};

type BreadcrumbsOfUserMyPageProps = {
  userRouting: UserRoutingPaths; // 現在のマイページのパス
  subRouting?: UserManagementPaths | UserAnalysisPaths; // 現在のサブパス（ある場合）
};

/**
 * ユーザーマイページのパンくずリストコンポーネント
 *
 * @param props
 * @constructor
 */
export const BreadcrumbsOfUserMyPage: React.FC<BreadcrumbsOfUserMyPageProps> = (
  props: BreadcrumbsOfUserMyPageProps
): ReactElement => {
  const theme = useTheme();
  const screenIsMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Breadcrumbs maxItems={screenIsMobile ? 2 : 8}>
      <MuiLink component={Link} color={"inherit"} to={RoutingPaths.top}>
        ST Cloud TOP
      </MuiLink>
      <MuiLink
        component={Link}
        color={"inherit"}
        to={RoutingPaths.userMyPage + UserRoutingPaths.analysis}
      >
        マイページ
      </MuiLink>
      {props.subRouting === undefined ? (
        <Typography color={"textPrimary"}>
          {getLinkNameFromUserRouting(props.userRouting)}
        </Typography>
      ) : (
        <MuiLink
          component={Link}
          color={"inherit"}
          to={RoutingPaths.userMyPage + props.userRouting}
        >
          {getLinkNameFromUserRouting(props.userRouting)}
        </MuiLink>
      )}
      {props.subRouting !== undefined && (
        <Typography color={"textPrimary"}>
          {getLinkNameFromManagementPath(props.subRouting)}
        </Typography>
      )}
    </Breadcrumbs>
  );
};
