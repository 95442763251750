// ページ説明文
export const WEB_DESCRIPTION_PHRASE = "Voice Emotion Recognition API";
// サブページ説明文
export const WEB_SUB_DESCRIPTION_PHRASE = "Analyze emotions from Voice.";
// ロゴへのパス
export const LOGO_SRC_PATH = process.env.PUBLIC_URL + "/logo.png";
// APIドキュメントへのパス
export const API_DOCUMENT_PATH =
  process.env.PUBLIC_URL + "/docs/st-cloud-api.v2.0.html";

/**
 * ルーティングパス情報
 */
export enum RoutingPaths {
  top = "/",
  pricing = "/pricing",
  signUp = "/sign-up",
  login = "/login",
  userMyPage = "/my-page",
  passwordReset = "/password-reset",
  noPage = "/404",
}

/**
 * ユーザ登録パス情報
 */
export enum SignUpRoutingPaths {
  input = "/input", // 必要描法の入力
  sentEmail = "/sent-email", // 確認メール送信済み画面
  confirmedEmail = "/confirmed-email-address", // メールアドレス確認完了画面
  emailError = "/email-address-error", // メールアドレス確認失敗
}

/**
 * ユーザーマイページパス情報
 */
export enum UserRoutingPaths {
  analysis = "/analysis",
  dashboard = "/dashboard",
  management = "/management",
  documents = "/documents",
  logout = "/logout",
}

/**
 * パスワード再設定パス情報
 */
export enum PasswordRestPaths {
  application = "/application", // 申請
  sentResetEmail = "/sent-reset-email", // パスワードリセット用のメール送信済み画面
  newPassword = "/new-password", // 新パスワード入力画面
  changedPassword = "/changed-password", // 新パスワード設定済み画面
}

/**
 * 音声解析パス
 */
export enum UserAnalysisPaths {
  result = "/result", // 解析結果
}

/**
 * ユーザ管理パス情報
 */
export enum UserManagementPaths {
  changePassword = "/password", // パスワード変更
}

/**
 * チャンネル番号
 */
export enum ChannelId {
  monaural,
  left,
  right,
}

/**
 * 発話の表示色
 */
export enum UtteranceColor {
  lowLevel = "#CCCCCC", // 低スコア不明カラー
  sameLevel = "#E5DDC4", // 同スコア不明カラー
  longUtterance = "#AFAFB0", // 発話が長い不明カラー
  sameMergedLevel = "#E5DDC4", // マージスコアが同じ不明カラー
  notVoice = "#BF97C4", // 音声外のカラー
  joy1 = "#FFD900", // 嬉しそうカラー
  joy2 = "#FFFD7A", // 楽しそうカラー
  anger1 = "#F10732", // 怒っていそうカラー
  anger2 = "#C90B42", // ムッとしていそうカラー
  anger3 = "#EC6D74", // 嫌そうカラー
  sorrow1 = "#0095DF", // 沈んでいそうカラー
  sorrow2 = "#0C3388", // 悲しそうカラー
  calm = "#12C33B", // 単調カラー
  restriction = "#808080", // 解析制限
  error = "#00FFFF", // エラー時の色
}

/**
 * 4感情の色
 */
export enum Emotion4Color {
  joy = "#FCCF00", // 喜び(4感情)
  anger = "#E50012", // 怒り(4感情)
  sorrow = "#0094DF", // 哀しみ(4感情)
  calm = "#12AF3C", // 単調(4感情)
}

export const UTTERANCE_FINISH_CODE_OF_RESTRICTION = 10; // 解析制限時の発話のfinishCode

/**
 * ユーザのライセンス情報
 */
export type LicenseInfo = {
  loginId: string;
  email: string;
  remainingAnalysisCount: number;
  analysisCountLimit: number;
  licenseExpiredDate: string;
  licenseType: number;
  createdAt: string;
};
