import React, { ReactElement } from "react";
import { Link, Typography } from "@material-ui/core";

export const Copyright: React.FC = (): ReactElement => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link
        color={"inherit"}
        href={"https://www.agi-web.co.jp/"}
        target={"_blank"}
        rel={"noopener"}
      >
        AGI Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};
