import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const TRACKING_ID = "UA-180221031-1";

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { page_path: string }
    ) => void;
  }
}

/**
 * Google Analyticsにトラッキングデータを送る
 */
export const useTracking = () => {
  const history = useHistory();

  useEffect(() => {
    const unListen = history.listen((location) => {
      if (!window.gtag) return;
      window.gtag("config", TRACKING_ID, { page_path: location.pathname });
    });

    return () => unListen();
  }, [history]);
};
