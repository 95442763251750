import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  createStyles,
  Grid,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { basePathOfApiServer } from "../../utils/utility";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveLine, Datum, Serie } from "@nivo/line";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 800,
      width: "90%",
      margin: "0 auto",
    },
    paper: {
      padding: theme.spacing(1),
    },
    tableTitle: {
      margin: theme.spacing(2),
    },
    errorMessage: {
      margin: theme.spacing(2),
    },
    monthlyUsageTableContainer: {
      whiteSpace: "nowrap",
      maxHeight: 150,
    },
    analysisCountCol: {
      textAlign: "right",
    },
    utteranceCountCol: {
      textAlign: "right",
    },
    paymentCol: {
      textAlign: "right",
    },
    countAreaChart: {
      height: 250,
    },
    dateRangeWrapper: {
      marginTop: 45,
      marginBottom: 10,
      paddingLeft: 60,
      paddingRight: 20,
    },
  })
);

const DivAreaChartTooltip = styled.div`
  background: white;
  padding: 9px 12px;
  border: 1px solid #ccc;
`;

/**
 * 一日ごとの解析結果数
 */
type DailyCountSummary = {
  date: string; // 日付
  analysisRequestCount: number; // 解析リクエスト回数
  analyzedUtteranceCount: number[]; // 音声チャンネルごとの解析実行発話数
};

/**
 * 解析回数集計結果取得レスポンス
 */
type ResultCountSummaryResponse = {
  countSummary: DailyCountSummary[];
};

const RESULT_COUNT_PATH = "/st-cloud/v2.0/results/count/summary";

/**
 * 解析回収集計結果を取得する
 *
 * @param accessToken ST Cloudのアクセストークン
 */
const postResultCountSummary = async (
  accessToken: string
): Promise<ResultCountSummaryResponse | null> => {
  try {
    const uri = basePathOfApiServer() + RESULT_COUNT_PATH;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let response = await axios.post(
      uri,
      {},
      {
        headers: headers,
      }
    );
    return response.data as ResultCountSummaryResponse;
  } catch (e) {
    console.error("[Get ResultCountSummary Error]", e);
    if (e.response && e.response.data) {
      console.error("[Get ResultCountSummary Error Response]", e.response.data);
    }
    return null;
  }
};

/**
 * 特定の月の解析回数を取得する
 *
 * @param summary 全体の解析回数
 * @param month 取得する月
 */
const getSpecificMonthSummary = (
  summary: ResultCountSummaryResponse,
  month: Date
): DailyCountSummary[] => {
  return summary.countSummary.filter((dailySummary) => {
    const resultDate = new Date(dailySummary.date);
    return (
      resultDate.getFullYear() === month.getFullYear() &&
      resultDate.getMonth() === month.getMonth()
    );
  });
};

// FIXME: 適当な数値なので後でちゃんとした値に変える
// 一解析ごとの価格(円)
const PAY_RATE_PER_ANALYSIS_COUNT = 10;

type MonthlyUsageStatusProps = {
  resultSummary?: ResultCountSummaryResponse;
  accountCreatedDate: Date;
};

/**
 * 月毎の利用状況コンポーネント
 *
 * @param props
 * @constructor
 */
const MonthlyUsageStatus: React.FC<MonthlyUsageStatusProps> = (
  props: MonthlyUsageStatusProps
): ReactElement => {
  const classes = useStyles();
  const thisMonth = new Date();
  thisMonth.setDate(1); // 31日ある月に一月引くと月初になるため、月の初めの日を設定する

  // 今月からアカウント作成日までのリストを作る
  const isAfterAccountCreatedMonth = (date: Date): boolean => {
    return (
      date.getFullYear() > props.accountCreatedDate.getFullYear() ||
      (date.getFullYear() === props.accountCreatedDate.getFullYear() &&
        date.getMonth() >= props.accountCreatedDate.getMonth())
    );
  };
  const monthList = [];
  for (
    const date = new Date(thisMonth);
    isAfterAccountCreatedMonth(date);
    date.setMonth(date.getMonth() - 1)
  ) {
    monthList.push(new Date(date));
  }

  return (
    <Paper className={classes.paper} style={{ height: "100%" }}>
      <Typography
        component="h3"
        variant="h6"
        color="primary"
        gutterBottom={true}
        className={classes.tableTitle}
      >
        利用状況 (月毎)
      </Typography>
      <TableContainer className={classes.monthlyUsageTableContainer}>
        <Table size={"small"} stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>期間</TableCell>
              <TableCell>解析ファイル数</TableCell>
              <TableCell>解析発話数</TableCell>
              <TableCell>ご利用料金 (円)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {monthList.map((month, index) => {
              const monthIsThisMonth =
                month.getFullYear() === thisMonth.getFullYear() &&
                month.getMonth() === thisMonth.getMonth();
              const monthlySummary = props.resultSummary
                ? getSpecificMonthSummary(props.resultSummary, month)
                : null;
              let fileCount = 0;
              let utteranceCount = 0;
              if (monthlySummary) {
                for (const dailySummary of monthlySummary) {
                  fileCount += dailySummary.analysisRequestCount;
                  utteranceCount += dailySummary.analyzedUtteranceCount.reduce(
                    (previousValue, currentValue) =>
                      currentValue + previousValue
                  );
                }
              }
              return (
                <TableRow key={index}>
                  <TableCell>
                    {month.getFullYear()}/
                    {(month.getMonth() + 1).toString().padStart(2, "0")}
                    {monthIsThisMonth && ` (未確定)`}
                  </TableCell>
                  <TableCell className={classes.analysisCountCol}>
                    {fileCount}
                  </TableCell>
                  <TableCell className={classes.utteranceCountCol}>
                    {utteranceCount}
                  </TableCell>
                  <TableCell className={classes.paymentCol}>{`\xA5 ${(
                    fileCount * PAY_RATE_PER_ANALYSIS_COUNT
                  ).toLocaleString()}`}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

/**
 * チャートの折れ線用データを取得する
 *
 * @param resultSummary 解析回数リスト
 * @param accountCreatedDate ユーザアカウントの作成日時
 */
const getLineData = (
  resultSummary: ResultCountSummaryResponse,
  accountCreatedDate: Date
): Datum[] => {
  // 日付をJSのDate型に変換する
  const tmpLineData = resultSummary.countSummary.map((dailySummary) => {
    const datum: Datum = {
      x: new Date(dailySummary.date),
      y: dailySummary.analysisRequestCount,
    };
    return datum;
  });

  // アカウント作成日から今日までの日付リストを作る
  const today = new Date();
  const firstResultDate = resultSummary.countSummary[0]
    ? new Date(resultSummary.countSummary[0].date)
    : null;
  const startDate =
    firstResultDate === null || firstResultDate > accountCreatedDate
      ? accountCreatedDate
      : firstResultDate;
  const dateList = [];

  // 日付単位で今日以内かを調べる
  const isWithinToday = (day: Date) =>
    day.getFullYear() < today.getFullYear() ||
    (day.getFullYear() === today.getFullYear() &&
      day.getMonth() < today.getMonth()) ||
    (day.getFullYear() === today.getFullYear() &&
      day.getMonth() === today.getMonth() &&
      day.getDate() <= today.getDate());

  for (
    const date = new Date(startDate);
    isWithinToday(date);
    date.setDate(date.getDate() + 1)
  ) {
    dateList.push(new Date(date));
  }

  // アカウント作成日から今日までの一日ごとの解析回数リストを作成する
  let idxOfSummary = 0;
  return dateList.map((date) => {
    const tmpDatum = tmpLineData[idxOfSummary];
    if (tmpDatum) {
      const tmpDatumX = tmpDatum.x as Date;
      if (
        date.getFullYear() === tmpDatumX.getFullYear() &&
        date.getMonth() === tmpDatumX.getMonth() &&
        date.getDate() === tmpDatumX.getDate()
      ) {
        ++idxOfSummary;
        return tmpDatum;
      } else {
        return { x: date, y: 0 };
      }
    } else {
      return { x: date, y: 0 };
    }
  });
};

const MAX_RANGE_OF_DISPLAY = 30 * 12; // 表示する範囲は最大一年
/**
 * 表示範囲を計算する
 *
 * @param range 新しく表示したい範囲
 * @param oldRange もともと表示していた範囲
 */
const calcVisibleRange = (range: number[], oldRange: number[]): number[] => {
  if (range[0] === undefined || range[1] === undefined) {
    return range;
  }
  if (range[1] - range[0] > MAX_RANGE_OF_DISPLAY) {
    if (range[1] !== oldRange[1]) {
      return [range[1] - MAX_RANGE_OF_DISPLAY, range[1]];
    } else {
      return [range[0], range[0] + MAX_RANGE_OF_DISPLAY];
    }
  } else {
    return range;
  }
};

type AnalysisCountChartProps = {
  resultSummary?: ResultCountSummaryResponse;
  accountCreatedDate: Date;
};

const ANALYSIS_COUNT_ID = "analysisCount";
/**
 * 日毎の利用状況コンポーネント
 */
const AnalysisCountChart: React.FC<AnalysisCountChartProps> = (
  props: AnalysisCountChartProps
): ReactElement => {
  const classes = useStyles();
  const [originalSeries, setOriginalSeries] = useState<Serie[]>([]);
  const [visibleSeries, setVisibleSeries] = useState<Serie[]>([]);
  const [dateRangeOfDisplay, setDateRangeOfDisplay] = React.useState([0, 1]);

  const handleChange = useCallback(
    (event: any, newValue: number | number[]) => {
      const range = calcVisibleRange(newValue as number[], dateRangeOfDisplay);
      setDateRangeOfDisplay(range);
    },
    [dateRangeOfDisplay]
  );

  // データが読み込まれた際に描画用データを作成し、表示範囲を設定する
  useEffect(() => {
    if (props.resultSummary) {
      const lineData = getLineData(
        props.resultSummary,
        props.accountCreatedDate
      );
      setOriginalSeries([{ id: ANALYSIS_COUNT_ID, data: lineData }]);
      const range = calcVisibleRange([0, lineData.length - 1], [0, 1]);
      setDateRangeOfDisplay(range);
    }
  }, [props.resultSummary, props.accountCreatedDate]);

  // 表示範囲を変える
  useEffect(() => {
    if (originalSeries[0]) {
      const data = originalSeries[0].data.slice(
        dateRangeOfDisplay[0],
        dateRangeOfDisplay[1] + 1
      );
      setVisibleSeries([{ id: ANALYSIS_COUNT_ID, data: data }]);
    }
  }, [originalSeries, dateRangeOfDisplay]);

  return (
    <Paper className={classes.paper}>
      <Typography
        component="h3"
        variant="h6"
        color="primary"
        gutterBottom={true}
        className={classes.tableTitle}
      >
        利用状況 (日毎)
      </Typography>
      <div className={classes.countAreaChart}>
        <ResponsiveLine
          data={visibleSeries}
          margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
          xScale={{
            type: "time",
            format: "native",
            useUTC: false,
            precision: "day",
          }}
          xFormat={"time:%Y-%m-%d"}
          yScale={{
            type: "linear",
            min: 0,
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -40,
            format: (date) =>
              `${(date as Date).getMonth() + 1}月${(date as Date).getDate()}日`,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 2,
            tickRotation: 0,
            legend: "解析ファイル数",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          enableGridX={false}
          pointSize={6}
          enableArea={true}
          useMesh={true}
          motionStiffness={300}
          motionDamping={40}
          tooltip={(value) => (
            <DivAreaChartTooltip>
              <div>{(value.point.data.x as Date).toLocaleDateString()}</div>
              <div>解析ファイル数: {value.point.data.y}</div>
            </DivAreaChartTooltip>
          )}
        />
      </div>
      <div className={classes.dateRangeWrapper}>
        <Slider
          value={dateRangeOfDisplay}
          onChange={handleChange}
          valueLabelDisplay={"on"}
          min={0}
          max={originalSeries[0] ? originalSeries[0].data.length - 1 : 1}
          valueLabelFormat={(dataIndex) => {
            const date = originalSeries[0]
              ? (originalSeries[0].data[dataIndex].x as Date)
              : new Date();
            return `${date.getMonth() + 1}/${date.getDate()}`;
          }}
        />
      </div>
    </Paper>
  );
};

type DashboardProps = {
  accessToken: string; // ST Cloudのアクセストークン
  accountCreatedAt?: string; // アカウント作成日時
};

/**
 * ダッシュボードコンポーネント
 *
 * @constructor
 */
export const Dashboard: React.FC<DashboardProps> = (
  props: DashboardProps
): ReactElement => {
  const classes = useStyles();
  const [resultCount, setResultCount] = useState<ResultCountSummaryResponse>();
  const [failedToGetResultCount, setFailedToGetResultCount] = useState(false);

  useEffect(() => {
    postResultCountSummary(props.accessToken).then((result) => {
      if (result) {
        setResultCount(result);
      } else {
        setFailedToGetResultCount(true);
      }
    });
  }, [props.accessToken]);

  const accountCreatedDate = useMemo(
    () => (props.accountCreatedAt ? new Date(props.accountCreatedAt) : null),
    [props.accountCreatedAt]
  );
  return (
    <div className={classes.root}>
      {failedToGetResultCount && (
        <Typography
          variant={"body1"}
          align={"center"}
          color={"error"}
          className={classes.errorMessage}
        >
          利用状況の取得に失敗しました。
        </Typography>
      )}
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          {accountCreatedDate && (
            <MonthlyUsageStatus
              accountCreatedDate={accountCreatedDate}
              resultSummary={resultCount}
            />
          )}
        </Grid>
        <Grid item={true} xs={12}>
          {accountCreatedDate && (
            <AnalysisCountChart
              resultSummary={resultCount}
              accountCreatedDate={accountCreatedDate}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
