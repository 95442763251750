import React, { ReactElement, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { clearAccessToken } from "../../utils/storage";

/**
 * ログアウト画面
 *
 * @constructor
 */
export const LogOutDialog: React.FC = (): ReactElement => {
  const routeHistory = useHistory();

  const handleCancel = useCallback(() => {
    routeHistory.goBack();
  }, [routeHistory]);

  const handleYes = () => {
    clearAccessToken();
    window.location.reload();
  };

  return (
    <Dialog open={true} onClose={handleCancel}>
      <DialogTitle>{"ログアウトしますか？"}</DialogTitle>
      <DialogActions>
        <Grid container={true} justify={"space-around"}>
          <Grid item={true}>
            <Button onClick={handleYes} color={"primary"} size={"small"}>
              はい
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              onClick={handleCancel}
              color={"primary"}
              size={"small"}
              autoFocus
            >
              いいえ
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
