import React, { ReactElement } from "react";
import {
  createStyles,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { API_DOCUMENT_PATH } from "../../CommonTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      maxWidth: 700,
      margin: "0 auto",
    },
    tableWrapper: {
      overflowX: "auto",
    },
    tableTitle: {
      margin: theme.spacing(2),
    },
    table: {
      whiteSpace: "nowrap",
    },
    launchIcon: {
      marginLeft: 3,
      verticalAlign: "middle",
    },
  })
);

type TableRowDocumentItemProps = {
  category: string;
  documentLink: ReactElement;
  updatedDate: string;
};

/**
 * ユーザ情報の各行
 *
 * @param props
 * @constructor
 */
const TableRowDocumentItem: React.FC<TableRowDocumentItemProps> = (
  props: TableRowDocumentItemProps
) => {
  return (
    <TableRow>
      <TableCell>{props.category}</TableCell>
      <TableCell>{props.documentLink}</TableCell>
      <TableCell>{props.updatedDate}</TableCell>
    </TableRow>
  );
};

/**
 * ドキュメントコンポーネント
 *
 * @constructor
 */
export const Documents: React.FC = () => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Typography
        className={classes.tableTitle}
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
      >
        ドキュメント
      </Typography>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} size={"small"} stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>カテゴリ</TableCell>
              <TableCell>タイトル</TableCell>
              <TableCell>掲載日</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowDocumentItem
              category={"仕様書"}
              documentLink={
                <MuiLink
                  href={API_DOCUMENT_PATH}
                  target={"_blank"}
                  rel={"noopener"}
                >
                  ST Cloud REST API仕様書
                  {
                    <Launch
                      className={classes.launchIcon}
                      fontSize={"inherit"}
                    />
                  }
                </MuiLink>
              }
              updatedDate={"2021-03-08"}
            />
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
};
