import React, {
  ReactElement,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Measure from "react-measure";
import {
  getUtteranceColorFromEmo4,
  getUtteranceColorFromEmo8,
  isEmotion8Unknown,
  readAudioFileData,
} from "../../../utils/utility";
import { ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN } from "./AnalysisResultStyles";
import {
  AnalysisV20Config,
  AnalysisV20ResultUtteranceList,
} from "../../../lib";
import {
  ChannelId,
  UTTERANCE_FINISH_CODE_OF_RESTRICTION,
} from "../../../CommonTypes";
import styled from "styled-components";
import {
  Button,
  ButtonGroup,
  createStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const BASE_CANVAS_HEIGHT = 200;
const TIME_LINE_TICK_HEIGHT = 10;
const TIME_LINE_TICK_MARGIN = 20 + TIME_LINE_TICK_HEIGHT;

const DivTimelineWrapper = styled.div`
  position: relative;
  overflow-x: auto;
`;

const ScaleButton = styled(Button)<{ selected: boolean }>`
  background-color: ${({ selected }) =>
    selected ? "rgba(0, 0, 0, 0.1)" : "transparent"};
`;

const CanvasProgressCursor = styled.canvas`
  top: 0;
  left: 0;
  position: absolute;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    displayScaleWrapper: {
      marginBottom: "5px",
      padding: `0 ${ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN}px`,
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      },
    },
  })
);

/**
 * タイムラインの中身の幅を算出する
 *
 * @param width キャンバスの横幅
 */
const calcTlContentWidth = (width: number): number => {
  return width - ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN * 2;
};

/**
 * 時間軸上の区切り秒数表示の文字列を生成する
 *
 * @param milliSec ミリ秒
 */
const generateTimeTickString = (milliSec: number): string => {
  return (milliSec / 1000).toFixed(1).toString();
};

/**
 * チャンネルの音声波形を描画する
 *
 * @param channelData チャンネルの音声データ
 * @param tlContentWidth タイムラインのキャンバスの横幅
 * @param startPositionShit 波形開始位置のずらし
 * @param waveHeight 波形を描画する高さ
 * @param channelCount 描画対象のチャンネルカウント
 * @param ctx 描画先
 */
const drawAudioChannelWave = (
  channelData: Float32Array,
  tlContentWidth: number,
  startPositionShit: number,
  waveHeight: number,
  channelCount: number,
  ctx: CanvasRenderingContext2D
) => {
  const shift = 0.5;
  const eachBufferSize = channelData.length / tlContentWidth;

  const topMarginHeight = TIME_LINE_TICK_MARGIN + waveHeight * channelCount;
  const centerHeight = waveHeight / 2 + shift;
  ctx.moveTo(startPositionShit, topMarginHeight + centerHeight);
  for (let index = 0; index < tlContentWidth; ++index) {
    let min: number | undefined, max: number | undefined;
    const startIdx = Math.round(index * eachBufferSize);
    const end =
      startIdx + eachBufferSize > channelData.length
        ? channelData.length
        : Math.round(startIdx + eachBufferSize);
    for (let idxOfEachVal = startIdx; idxOfEachVal < end; ++idxOfEachVal) {
      const eachVal = channelData[idxOfEachVal];
      if (min === undefined || max === undefined) {
        min = max = eachVal;
      }
      if (eachVal < min) {
        min = eachVal;
      }
      if (eachVal > max) {
        max = eachVal;
      }
    }
    if (typeof min === "number" && typeof max === "number") {
      const x = index + startPositionShit;
      const maxY =
        Math.floor(centerHeight - max * centerHeight) + topMarginHeight + shift;
      const minY =
        Math.floor(centerHeight - min * centerHeight) + topMarginHeight + shift;
      ctx.lineTo(x, maxY);
      ctx.lineTo(x, minY);
    }
  }
  ctx.stroke();
};

/**
 * 音声の各チャンネルを合成し、モノラル音声を取得する
 *
 * @param inputBuffer 合成する音声データ
 */
const combineChannelBuffers = (inputBuffer: AudioBuffer): AudioBuffer => {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const ctx = new AudioContext();
  const combinedBuffer = ctx.createBuffer(
    1,
    inputBuffer.length,
    inputBuffer.sampleRate
  );
  const combinedChannelData = combinedBuffer.getChannelData(0);
  for (
    let channelIdx = 0;
    channelIdx < inputBuffer.numberOfChannels;
    ++channelIdx
  ) {
    const channelSamples = inputBuffer.getChannelData(channelIdx);
    for (let sampleIdx = 0; sampleIdx < channelSamples.length; ++sampleIdx) {
      if (combinedChannelData[sampleIdx] === undefined) {
        combinedChannelData[sampleIdx] =
          channelSamples[sampleIdx] / inputBuffer.numberOfChannels;
      } else {
        combinedChannelData[sampleIdx] +=
          channelSamples[sampleIdx] / inputBuffer.numberOfChannels;
      }
    }
  }
  return combinedBuffer;
};

/**
 * 時系列感情を描画する
 *
 * @param canvas キャンバス要素
 * @param width キャンバスの横幅
 * @param height キャンバスの高さ
 * @param duration 音声長（秒）
 * @param channelSize チャンネル数
 * @param utterances 発話一覧
 * @param buffer 音声データ
 * @param doesCombineChannels チャンネルを合成するかどうか
 */
const drawTimeLineCanvas = (
  canvas: HTMLCanvasElement,
  width: number,
  height: number,
  duration: number,
  channelSize: number,
  utterances: AnalysisV20ResultUtteranceList[],
  buffer: AudioBuffer | null,
  doesCombineChannels: boolean
): string | null => {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return null;
  }
  ctx.clearRect(0, 0, width, height);

  const audioDurationMilliSec = duration * 1000;
  const tlContentWidth = calcTlContentWidth(width);
  const overallUtterancesHeight = height - TIME_LINE_TICK_MARGIN;
  const channelUtterancesHeight = doesCombineChannels
    ? overallUtterancesHeight
    : overallUtterancesHeight / channelSize;
  const shift = 0.5;
  const startPositionShit =
    ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN + shift;

  // 時間軸描画
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.strokeStyle = "dimgray";
  ctx.textBaseline = "top";
  ctx.textAlign = "center";
  ctx.font = "10px 'sans-serif'";
  ctx.fillStyle = "dimgray";
  for (
    let milliSecTick = 0;
    milliSecTick < audioDurationMilliSec;
    milliSecTick += 1000
  ) {
    const x =
      (milliSecTick / audioDurationMilliSec) * tlContentWidth +
      startPositionShit;
    if (milliSecTick % 5000 === 0) {
      ctx.fillText(generateTimeTickString(milliSecTick), x, 0);
    }
    ctx.moveTo(x, TIME_LINE_TICK_MARGIN - TIME_LINE_TICK_HEIGHT);
    ctx.lineTo(x, TIME_LINE_TICK_MARGIN);
  }
  // 音声の最終秒数を時間軸上に描画
  const finishAudioIndexOfCtx = tlContentWidth + startPositionShit;
  ctx.fillText(
    generateTimeTickString(audioDurationMilliSec),
    finishAudioIndexOfCtx,
    0
  );
  ctx.moveTo(
    finishAudioIndexOfCtx,
    TIME_LINE_TICK_MARGIN - TIME_LINE_TICK_HEIGHT
  );
  ctx.lineTo(finishAudioIndexOfCtx, TIME_LINE_TICK_MARGIN);
  ctx.stroke();

  // 発話を描画
  for (const utterance of utterances) {
    const regionStart =
      Math.floor(
        (utterance.startMilliSecond / audioDurationMilliSec) * tlContentWidth
      ) + startPositionShit;
    const regionWidth = Math.floor(
      ((utterance.endMilliSecond - utterance.startMilliSecond) /
        audioDurationMilliSec) *
        tlContentWidth
    );
    let color: string;
    const restriction =
      utterance.voiceEmotion.finishCode ===
      UTTERANCE_FINISH_CODE_OF_RESTRICTION;
    if (!isEmotion8Unknown(utterance.voiceEmotion.emotion8Conf)) {
      color = getUtteranceColorFromEmo8(
        utterance.voiceEmotion.emotion8Conf,
        utterance.voiceEmotion.finishCode,
        restriction
      );
    } else {
      color = getUtteranceColorFromEmo4(
        utterance.voiceEmotion.emotion4Conf,
        utterance.voiceEmotion.finishCode,
        restriction
      );
    }
    const utteranceTopHeight =
      utterance.channelId === ChannelId.monaural
        ? TIME_LINE_TICK_MARGIN
        : (utterance.channelId - 1) * channelUtterancesHeight +
          TIME_LINE_TICK_MARGIN;

    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.strokeStyle = "white";
    ctx.moveTo(regionStart, utteranceTopHeight - shift);
    ctx.lineTo(regionStart + regionWidth, utteranceTopHeight - shift);
    ctx.lineTo(
      regionStart + regionWidth,
      utteranceTopHeight - shift + channelUtterancesHeight
    );
    ctx.lineTo(
      regionStart,
      utteranceTopHeight - shift + channelUtterancesHeight
    );
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    const fontSize = 12;
    const transcription =
      utterance.textEmotion &&
      regionWidth > utterance.textEmotion.transcription.length * fontSize
        ? `. ${utterance.textEmotion.transcription}`
        : "";
    const text = `${utterance.utteranceId}${transcription}`;
    ctx.fillStyle = "black";
    ctx.textAlign = "left";
    ctx.textBaseline = "bottom";
    ctx.font = `${fontSize}px 'sans-serif'`;
    ctx.fillText(
      text,
      regionStart + 2,
      utteranceTopHeight + channelUtterancesHeight - 5,
      regionWidth
    );
  }

  // 波形描画
  if (buffer) {
    const targetBufferToDraw = doesCombineChannels
      ? combineChannelBuffers(buffer)
      : buffer;
    ctx.strokeStyle = "#000063";
    ctx.beginPath();
    for (
      let channel = 0;
      channel < targetBufferToDraw.numberOfChannels;
      ++channel
    ) {
      const channelData = targetBufferToDraw.getChannelData(channel);
      drawAudioChannelWave(
        channelData,
        tlContentWidth,
        startPositionShit,
        channelUtterancesHeight,
        channel,
        ctx
      );
    }
  } else {
    ctx.beginPath();
    ctx.font = "14px 'sans-serif'";
    ctx.fillStyle = "red";
    ctx.textAlign = "left";
    ctx.textBaseline = "top";
    ctx.fillText(
      "このファイル形式は波形の描画に対応していません",
      startPositionShit,
      TIME_LINE_TICK_MARGIN,
      tlContentWidth
    );
    ctx.stroke();
  }

  // 枠で囲う
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.strokeStyle = "dimgray";
  ctx.rect(
    startPositionShit,
    TIME_LINE_TICK_MARGIN - shift,
    tlContentWidth,
    overallUtterancesHeight
  );
  ctx.stroke();

  return canvas.toDataURL("image/jpeg", 1);
};

/**
 * 音声の再生位置を計算する
 *
 * @param progressSec 現在の再生秒数
 * @param audioDurationSec 音声の全体秒数
 * @param contentWidth 描画Canvasの横幅
 */
const calcProgressCursorPosition = (
  progressSec: number,
  audioDurationSec: number,
  contentWidth: number
): number => {
  return (
    (progressSec / audioDurationSec) * contentWidth +
    ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN
  );
};

/**
 * 再生カーソルを描画する
 *
 * @param canvas キャンバス要素
 * @param progressSec 再生秒数
 * @param width 横幅
 * @param height 縦幅
 * @param audioDurationSec 音声の長さ(秒)
 */
const drawProgressCursor = (
  canvas: HTMLCanvasElement,
  progressSec: number,
  width: number,
  height: number,
  audioDurationSec: number
): void => {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return;
  }
  ctx.clearRect(0, 0, width, height);

  const contentWidth = calcTlContentWidth(width);
  ctx.beginPath();
  ctx.strokeStyle = "black";
  ctx.lineWidth = 1;
  const x = calcProgressCursorPosition(
    progressSec,
    audioDurationSec,
    contentWidth
  );
  ctx.moveTo(x, TIME_LINE_TICK_MARGIN);
  ctx.lineTo(x, height);
  ctx.stroke();
};

// 波形描画スケール種別
type DisplayScaleType = "all" | "5sec" | "15sec" | "30sec" | "1min";

/**
 * 時系列感情描画の引数
 */
type EmotionTimeLineProps = {
  config: AnalysisV20Config; // 解析設定
  utterances: AnalysisV20ResultUtteranceList[]; // 発話一覧
  audio: RefObject<HTMLAudioElement>; // 音声要素
  loadingFile: File; // 読込中ファイル
  imageSetter: React.Dispatch<string | null>; // 描画した時系列データ画像の保存セッター
};

/**
 * 時系列の感情描画コンポーネント
 *
 * @param props
 * @constructor
 */
export const EmotionTimeLine: React.FC<EmotionTimeLineProps> = (
  props: EmotionTimeLineProps
): ReactElement => {
  const [componentWidth, setComponentWidth] = useState(0);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);
  const [displayScale, setDisplayScale] = useState<DisplayScaleType>("all");
  const tlCanvasRef = useRef<HTMLCanvasElement>(null);
  const cursorCanvasRef = useRef<HTMLCanvasElement>(null);
  const tlWrapperRef = useRef<HTMLDivElement>(null);
  const imageSetter = props.imageSetter;
  const channelSize = useMemo(() => {
    if (audioBuffer !== null) {
      if (props.config.combineChannels === true) {
        return 1;
      } else {
        return audioBuffer.numberOfChannels;
      }
    } else {
      let channelSize = 1;
      props.utterances.forEach(
        (utterance) =>
          (channelSize =
            utterance.channelId > channelSize
              ? utterance.channelId
              : channelSize)
      );
      return channelSize;
    }
  }, [audioBuffer, props.config.combineChannels, props.utterances]);
  const canvasHeight = useMemo(() => BASE_CANVAS_HEIGHT * channelSize, [
    channelSize,
  ]);
  const canvasWidth = useMemo(() => {
    if (props.audio.current !== null) {
      switch (displayScale) {
        case "all":
          return componentWidth;
        case "5sec":
          return (props.audio.current.duration / 5) * componentWidth;
        case "15sec":
          return (props.audio.current.duration / 15) * componentWidth;
        case "30sec":
          return (props.audio.current.duration / 30) * componentWidth;
        case "1min":
          return (props.audio.current.duration / 60) * componentWidth;
      }
    } else {
      return componentWidth;
    }
  }, [displayScale, props.audio, componentWidth]);
  const theme = useTheme();
  const screenIsMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  // 音声データ読み込み
  useEffect(() => {
    readAudioFileData(props.loadingFile).then((buffer) =>
      setAudioBuffer(buffer)
    );
  }, [props.loadingFile]);

  // 時系列描画
  useEffect(() => {
    const draw = () => {
      if (tlCanvasRef.current && props.audio.current) {
        const data = drawTimeLineCanvas(
          tlCanvasRef.current,
          canvasWidth,
          canvasHeight,
          props.audio.current.duration,
          channelSize,
          props.utterances,
          audioBuffer,
          !!props.config.combineChannels
        );
        imageSetter(data);
      }
    };
    draw();

    // MEMO: 音声読み込みが終わっていない場合のため、イベントリスナーを登録する
    if (props.audio.current) {
      props.audio.current.addEventListener("loadedmetadata", draw);
    }
    return () => {
      if (props.audio.current) {
        props.audio.current.removeEventListener("loadedmetadata", draw);
      }
    };
  }, [
    props.utterances,
    props.config.combineChannels,
    props.audio,
    channelSize,
    audioBuffer,
    imageSetter,
    canvasWidth,
    canvasHeight,
  ]);

  // 音声再生状態管理
  useEffect(() => {
    setAudioPlaying(false);
    const handlePlay = () => setAudioPlaying(true);
    const handlePause = () => setAudioPlaying(false);
    if (props.audio.current) {
      props.audio.current.addEventListener("play", handlePlay);
      props.audio.current.addEventListener("pause", handlePause);
    }
    return () => {
      if (props.audio.current) {
        props.audio.current.removeEventListener("pause", handlePause);
        props.audio.current.removeEventListener("play", handlePlay);
      }
    };
  }, [props.audio]);

  // 音声再生位置描画
  const animationIdRef = useRef<number>();
  useEffect(() => {
    const scrollCanvasToCenter = () => {
      if (props.audio.current && tlWrapperRef.current) {
        const x = calcProgressCursorPosition(
          props.audio.current.currentTime,
          props.audio.current.duration,
          canvasWidth
        );
        const left = x - componentWidth / 2;
        tlWrapperRef.current.scrollLeft = left < 0 ? 0 : left;
        if (cursorCanvasRef.current) {
          cursorCanvasRef.current.scrollLeft = tlWrapperRef.current.scrollLeft;
        }
      }
    };

    if (audioPlaying) {
      const draw = () => {
        if (
          cursorCanvasRef.current &&
          props.audio.current &&
          tlWrapperRef.current
        ) {
          drawProgressCursor(
            cursorCanvasRef.current,
            props.audio.current.currentTime,
            canvasWidth,
            canvasHeight,
            props.audio.current.duration
          );
          scrollCanvasToCenter();
        }
        animationIdRef.current = requestAnimationFrame(draw);
      };
      animationIdRef.current = requestAnimationFrame(draw);
      return () =>
        animationIdRef.current !== undefined
          ? cancelAnimationFrame(animationIdRef.current)
          : undefined;
    } else {
      scrollCanvasToCenter();
    }
  }, [props.audio, componentWidth, canvasWidth, canvasHeight, audioPlaying]);

  return (
    <Measure
      bounds
      onResize={(contentRect): void => {
        if (contentRect.bounds) {
          setComponentWidth(contentRect.bounds.width);
        }
      }}
    >
      {({ measureRef }): ReactElement => (
        <div ref={measureRef}>
          <div className={classes.displayScaleWrapper}>
            <span>{screenIsMobile ? "" : "波形描画スケール："}</span>
            <ButtonGroup size={"small"} disableElevation={true}>
              <ScaleButton
                selected={displayScale === "all"}
                onClick={() => setDisplayScale("all")}
              >
                全体
              </ScaleButton>
              <ScaleButton
                selected={displayScale === "5sec"}
                onClick={() => setDisplayScale("5sec")}
              >
                5秒
              </ScaleButton>
              <ScaleButton
                selected={displayScale === "15sec"}
                onClick={() => setDisplayScale("15sec")}
              >
                15秒
              </ScaleButton>
              <ScaleButton
                selected={displayScale === "30sec"}
                onClick={() => setDisplayScale("30sec")}
              >
                30秒
              </ScaleButton>
              <ScaleButton
                selected={displayScale === "1min"}
                onClick={() => setDisplayScale("1min")}
              >
                1分
              </ScaleButton>
            </ButtonGroup>
          </div>
          <DivTimelineWrapper ref={tlWrapperRef}>
            <canvas
              ref={tlCanvasRef}
              width={canvasWidth}
              height={canvasHeight}
            />
            {audioPlaying && (
              <CanvasProgressCursor
                ref={cursorCanvasRef}
                width={canvasWidth}
                height={canvasHeight}
              />
            )}
          </DivTimelineWrapper>
        </div>
      )}
    </Measure>
  );
};
