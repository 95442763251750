import React, { ReactElement, useCallback, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { RoutingPaths, UserRoutingPaths } from "../../CommonTypes";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  basePathOfApiServer,
  isPasswordValidLength,
  MAX_ST_CLOUD_PASSWORD_LENGTH,
  MIN_ST_CLOUD_PASSWORD_LENGTH,
} from "../../utils/utility";
import axios from "axios";

// ログイン用URI
const PASSWORD_CHANGE_PATH = "/st-cloud/v2.0/user/password/change";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * パスワードの変更要求を行う
 *
 * @param token アクセストークン
 * @param newPassword 新しく設定するパスワード
 */
const postPasswordChangeRequest = async (
  token: string,
  newPassword: string
): Promise<boolean> => {
  try {
    const uri = basePathOfApiServer() + PASSWORD_CHANGE_PATH;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    await axios.post(
      uri,
      {
        newPassword,
      },
      {
        headers: headers,
      }
    );
    return true;
  } catch (e) {
    console.error("[Password Change Request Error]", e);
    return false;
  }
};

type PasswordTextFieldProps = {
  label: string;
  autoFocus: boolean;
  value: string;
  changeHandler: React.Dispatch<string>;
  error?: boolean;
  helperText?: string;
};

/**
 * パスワード入力フィールド
 *
 * @param props
 * @constructor
 */
const PasswordTextField: React.FC<PasswordTextFieldProps> = (
  props: PasswordTextFieldProps
): ReactElement => {
  return (
    <TextField
      label={props.label}
      variant={"outlined"}
      required={true}
      fullWidth={true}
      margin={"normal"}
      name={"password"}
      type={"password"}
      error={props.error}
      helperText={props.helperText}
      autoFocus={props.autoFocus}
      value={props.value}
      onChange={(e) => props.changeHandler(e.target.value)}
    />
  );
};

/**
 * パスワード変更終了ダイアログ
 *
 * @constructor
 */
const DialogPasswordChanged: React.FC = (): ReactElement => {
  const routeHistory = useHistory();

  const handleClose = useCallback(() => {
    routeHistory.push(RoutingPaths.userMyPage + UserRoutingPaths.management);
  }, [routeHistory]);

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>{"パスワードを変更しました"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * パスワード変更コンポーネント
 *
 * @constructor
 */
export const PasswordEditor: React.FC<{ accessToken: string }> = (props: {
  accessToken: string;
}): ReactElement => {
  const classes = useStyles();

  const [oldPasswordConfirm, setOldPasswordConfirm] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [showFinishDialog, setShowFinishDialog] = useState(false);
  const [failedToChangePass, setFailedToChangePass] = useState(false);

  const newPasswordIsValid =
    oldPasswordConfirm &&
    isPasswordValidLength(newPassword) &&
    newPassword === newPasswordConfirm;

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Typography component="h1" variant="h5" align={"center"}>
            パスワード変更
          </Typography>
          {failedToChangePass && (
            <Typography
              variant={"body1"}
              align={"center"}
              color={"error"}
              className={classes.errorMessage}
            >
              パスワードの変更に失敗しました。
            </Typography>
          )}
          <PasswordTextField
            label={"現在のパスワード"}
            value={oldPasswordConfirm}
            changeHandler={setOldPasswordConfirm}
            autoFocus={true}
          />
          <PasswordTextField
            label={"新しいパスワード"}
            value={newPassword}
            changeHandler={setNewPassword}
            error={newPassword !== "" && !isPasswordValidLength(newPassword)}
            helperText={
              MIN_ST_CLOUD_PASSWORD_LENGTH.toString() +
              "文字以上" +
              MAX_ST_CLOUD_PASSWORD_LENGTH.toString() +
              "文字以内"
            }
            autoFocus={false}
          />
          <PasswordTextField
            label={"新しいパスワードの確認"}
            value={newPasswordConfirm}
            changeHandler={setNewPasswordConfirm}
            error={
              newPasswordConfirm !== "" && newPassword !== newPasswordConfirm
            }
            autoFocus={false}
          />
          <Button
            className={classes.submit}
            type={"submit"}
            color={"primary"}
            variant={"contained"}
            fullWidth={true}
            size={"large"}
            disabled={!newPasswordIsValid}
            onClick={(e) => {
              e.preventDefault();
              postPasswordChangeRequest(props.accessToken, newPassword).then(
                (success) => {
                  if (success) {
                    setShowFinishDialog(true);
                  } else {
                    setFailedToChangePass(true);
                  }
                }
              );
            }}
          >
            パスワードを変更
          </Button>
        </form>
      </div>
      {showFinishDialog && <DialogPasswordChanged />}
    </Container>
  );
};
