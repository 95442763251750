import {
  AnalysisApi,
  AnalysisV20Config,
  AnalysisV20Request,
  AnalysisV20Result,
  AudioInfo,
} from "../lib";
import { basePathOfApiServer, getAudioType } from "./utility";

// 解析するファイル名の最大長
const MAX_REQUEST_FILE_NAME = 255;

/**
 * 音声を読み込んでBASE64エンコーディングする
 *
 * @param file 音声ファイル
 */
const readAudioAndEncodeFromBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (event) => {
      let b64: string | undefined;
      if (event.target !== null && event.target.result !== null) {
        if (typeof event.target.result === "string") {
          let fromIndex = event.target.result.indexOf(",") + 1;
          b64 = event.target.result.substr(fromIndex);
        }
      }
      if (b64) {
        resolve(b64);
      } else {
        reject();
      }
    };
    reader.readAsDataURL(file);
  });
};

/**
 * リクエスト用オブジェクトを生成する
 *
 * @param file 音声ファイル
 * @param analysisConfig 解析オプション
 * @exception 対応していない拡張子が入力された場合
 */
const generateRequestFromAudioFile = async (
  file: File,
  analysisConfig?: AnalysisV20Config
): Promise<AnalysisV20Request> => {
  let b64Audio = await readAudioAndEncodeFromBase64(file);
  let audioType = getAudioType(file);
  if (audioType === null) {
    throw new Error(`Selected file type is not supported`);
  }

  const audio: AudioInfo = {
    audioDataType: audioType,
    audioData: b64Audio,
  };

  return {
    audio,
    config: analysisConfig,
  };
};

// 解析エラー
type AnalysisError = string;

/**
 * ファイル名非保存を解析設定に反映する
 *
 * @param baseConfig 元とする解析設定
 * @param notToSaveFileName ファイル名非保存設定
 * @param file 解析ファイル
 */
export const reflectNotSaveFileNameOptionToConfig = (
  baseConfig: AnalysisV20Config,
  notToSaveFileName: boolean,
  file: File
): AnalysisV20Config | AnalysisError => {
  if (notToSaveFileName) {
    return baseConfig;
  } else {
    const fileName = file.name;
    if (fileName.length > MAX_REQUEST_FILE_NAME) {
      return `ファイル名が長すぎます。ファイル名を${MAX_REQUEST_FILE_NAME}文字以内にしてください。`;
    } else {
      return { ...baseConfig, originalFileName: fileName };
    }
  }
};

/**
 * 解析要求をWeb APIに投げる
 *
 * @param file 解析要求対象ファイル
 * @param accessToken アクセストークン
 * @param analysisConfig 解析オプション
 * @throws 失敗時は例外
 */
export const postAnalysis = async (
  file: File,
  accessToken: string,
  analysisConfig?: AnalysisV20Config
): Promise<AnalysisV20Result> => {
  let requestObj = await generateRequestFromAudioFile(file, analysisConfig);
  const basePath = basePathOfApiServer();
  let client = new AnalysisApi(undefined, basePath);
  const requestOption = { headers: { Authorization: "Bearer " + accessToken } };
  const axiosResponse = await client.stCloudAnalysisV20Sync(
    requestObj,
    requestOption
  );
  return axiosResponse.data;
};
