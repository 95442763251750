import React, { ReactElement, useState } from "react";
import { BaseLayout } from "../BaseLayout";
import { useHistory, useLocation } from "react-router-dom";
import { Button, TextField, Theme, Typography } from "@material-ui/core";
import {
  basePathOfApiServer,
  isPasswordValidLength,
  MAX_ST_CLOUD_PASSWORD_LENGTH,
  MIN_ST_CLOUD_PASSWORD_LENGTH,
} from "../../utils/utility";
import { PasswordRestPaths, RoutingPaths } from "../../CommonTypes";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const RENEW_PASSWORD_PATH = "/st-cloud/v2.0/user/password/renew";

/**
 * 新規パスワードを設定する
 *
 * @param token パスワード再設定用のトークン
 * @param newPassword 新しいパスワード
 */
const postRenewPassword = async (
  token: string,
  newPassword: string
): Promise<boolean> => {
  try {
    const uri = basePathOfApiServer() + RENEW_PASSWORD_PATH;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    await axios.post(uri, { newPassword: newPassword }, { headers: headers });
    return true;
  } catch (e) {
    console.error("[Renew Password Error]", e);
    return false;
  }
};

/**
 * パスワード再設定時の新パスワード入力画面
 *
 * @constructor
 */
export const NewPassword: React.FC = (): ReactElement => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("t");
  const classes = useStyles();
  const routeHistory = useHistory();

  const [failedToResetPass, setFailedToResetPass] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const allInformationIsValid =
    isPasswordValidLength(password) && password === passwordConfirmation;

  return (
    <BaseLayout>
      <Typography component="h1" variant="h5" align={"center"}>
        新パスワード設定
      </Typography>
      {token === null ? (
        <React.Fragment>
          <Typography
            variant={"body1"}
            align={"center"}
            style={{ marginTop: 40 }}
          >
            URLが間違っています。
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {failedToResetPass && (
            <Typography
              variant={"body1"}
              align={"center"}
              color={"error"}
              className={classes.errorMessage}
            >
              パスワードの再設定に失敗しました。
            </Typography>
          )}
          <TextField
            label={"パスワード"}
            variant={"outlined"}
            margin={"normal"}
            required={true}
            fullWidth={true}
            name={"password"}
            type={"password"}
            error={!!password && !isPasswordValidLength(password)}
            inputProps={{ "data-testid": "passwordTest" }}
            helperText={
              MIN_ST_CLOUD_PASSWORD_LENGTH.toString() +
              "文字以上" +
              MAX_ST_CLOUD_PASSWORD_LENGTH.toString() +
              "文字以内"
            }
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label={"パスワード確認"}
            variant={"outlined"}
            margin={"normal"}
            required={true}
            fullWidth={true}
            name={"password"}
            type={"password"}
            error={!!passwordConfirmation && password !== passwordConfirmation}
            inputProps={{ "data-testid": "passwordConfirmTest" }}
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <Button
            className={classes.submit}
            type={"submit"}
            color={"primary"}
            variant={"contained"}
            fullWidth={true}
            size={"large"}
            disabled={!allInformationIsValid}
            data-testid={"applyResetButtonTest"}
            onClick={(e) => {
              e.preventDefault();
              postRenewPassword(token, password).then((success) => {
                if (success) {
                  routeHistory.push(
                    RoutingPaths.passwordReset +
                      PasswordRestPaths.changedPassword
                  );
                } else {
                  setFailedToResetPass(true);
                }
              });
            }}
          >
            新規パスワードを設定する
          </Button>
        </React.Fragment>
      )}
    </BaseLayout>
  );
};
