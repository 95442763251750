import React, { ReactElement, useEffect, useState } from "react";
import { createStyles, TextField, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const MIN_OF_CONF_THRESHOLD = 0; // 確定感情閾値の最小値
const MAX_OF_CONF_THRESHOLD = 100; // 確定感情閾値の最大値

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      margin: theme.spacing(1),
      width: 100,
    },
  })
);

type ConfigItemProps = {
  label: string; // 表示ラベル
  value?: number; // 現在の設定値
  default: number; // デフォルト値
  newValueSetter: (val: number) => void; // 新しい値のハンドラー
};

/**
 * 確定感情閾値の入力要素
 *
 * @param props
 * @constructor
 */
export const EmotionConfConfigItem: React.FC<ConfigItemProps> = (
  props: ConfigItemProps
): ReactElement => {
  const classes = useStyles();

  const currentValue = props.value !== undefined ? props.value : props.default;
  const [tmpValue, setTmpValue] = useState<number>(currentValue);
  const [error, setError] = useState(false);
  useEffect(() => setTmpValue(currentValue), [currentValue]);

  const title = (
    <React.Fragment>
      最小値: {MIN_OF_CONF_THRESHOLD}
      <br />
      最大値: {MAX_OF_CONF_THRESHOLD}
      <br />
      デフォルト値: {props.default}
    </React.Fragment>
  );

  return (
    <Tooltip title={title}>
      <TextField
        className={classes.text}
        type={"number"}
        label={props.label}
        inputProps={{
          step: 5,
          "data-testid": "emotion-conf-threshold-config-item",
          style: {
            textAlign: "center",
          },
        }}
        value={tmpValue}
        error={error}
        onChange={(event) => {
          const newVal = Number(event.target.value);
          if (
            newVal < MIN_OF_CONF_THRESHOLD ||
            newVal > MAX_OF_CONF_THRESHOLD
          ) {
            setError(true);
          } else {
            setError(false);
          }
          setTmpValue(newVal);
        }}
        onBlur={() => {
          if (tmpValue !== undefined) {
            let newVal = tmpValue;
            if (newVal < MIN_OF_CONF_THRESHOLD) {
              newVal = MIN_OF_CONF_THRESHOLD;
            } else if (newVal > MAX_OF_CONF_THRESHOLD) {
              newVal = MAX_OF_CONF_THRESHOLD;
            }
            props.newValueSetter(newVal);
            setTmpValue(newVal);
            setError(false);
          }
        }}
      />
    </Tooltip>
  );
};
