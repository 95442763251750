import React, { ReactElement, useState } from "react";
import { BaseLayout } from "../BaseLayout";
import { Button, TextField, Theme, Typography } from "@material-ui/core";
import { PasswordRestPaths, RoutingPaths } from "../../CommonTypes";
import { makeStyles } from "@material-ui/core/styles";
import { basePathOfApiServer } from "../../utils/utility";
import axios from "axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const RESET_PASSWORD_PATH = "/st-cloud/v2.0/user/password/reset";

/**
 * パスワードの再設定を申請する
 *
 * @param loginId ログインID
 */
const postApplyPasswordReset = async (loginId: string): Promise<boolean> => {
  try {
    const uri = basePathOfApiServer() + RESET_PASSWORD_PATH;
    const headers = {
      "Content-Type": "application/json",
    };
    await axios.post(
      uri,
      {
        loginId,
      },
      {
        headers: headers,
      }
    );
    return true;
  } catch (e) {
    console.error("[Reset Password Error]", e);
    return false;
  }
};

/**
 * パスワード再設定申請コンポーネント
 *
 * @constructor
 */
export const ApplyReset: React.FC = (): ReactElement => {
  const classes = useStyles();
  const routeHistory = useHistory();
  const [failedToReset, setFailedToReset] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <BaseLayout>
      <Typography component="h1" variant="h5" align={"center"}>
        パスワード再設定
      </Typography>
      {failedToReset && (
        <Typography
          variant={"body1"}
          align={"center"}
          color={"error"}
          className={classes.errorMessage}
        >
          パスワードの再設定に失敗しました。
        </Typography>
      )}
      <TextField
        label={"登録済みのメールアドレス"}
        variant={"outlined"}
        margin={"normal"}
        required={true}
        fullWidth={true}
        name={"email"}
        autoComplete={"email"}
        autoFocus={true}
        value={email}
        inputProps={{ "data-testid": "loginIdToResetTest" }}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        className={classes.submit}
        color={"primary"}
        variant={"contained"}
        fullWidth={true}
        size={"large"}
        disabled={!email}
        data-testid={"applyResetButtonTest"}
        onClick={(e) => {
          e.preventDefault();
          postApplyPasswordReset(email).then((success) => {
            if (success) {
              routeHistory.push(
                RoutingPaths.passwordReset + PasswordRestPaths.sentResetEmail
              );
            } else {
              setFailedToReset(true);
            }
          });
        }}
      >
        パスワード再設定用のメールを送信する
      </Button>
    </BaseLayout>
  );
};
