import React, { ReactElement } from "react";
import { VoiceEmotion4MergeConfThresholds } from "../../../lib";
import { EmotionConfConfigItem } from "./EmotionConfConfigItem";

const JOY_DEFAULT_THRESHOLD = 97;
const ANG_DEFAULT_THRESHOLD = 96;
const SRW_DEFAULT_THRESHOLD = 76;
const CLM_DEFAULT_THRESHOLD = 100;

type Emotion4MergeConfThresholdConfigProps = {
  currentConfig: VoiceEmotion4MergeConfThresholds | undefined; // 現在の設定値
  configSetter: (threshold: VoiceEmotion4MergeConfThresholds) => void; // 設定値のセッター
};

/**
 * 確定感情閾値の設定画面
 *
 * @param props
 * @constructor
 */
export const Emotion4MergeConfThresholdConfig: React.FC<Emotion4MergeConfThresholdConfigProps> = (
  props: Emotion4MergeConfThresholdConfigProps
): ReactElement => {
  return (
    <div>
      <EmotionConfConfigItem
        label={"喜び"}
        value={props.currentConfig?.joy}
        default={JOY_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, joy: val });
        }}
      />
      <EmotionConfConfigItem
        label={"怒り"}
        value={props.currentConfig?.anger}
        default={ANG_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, anger: val });
        }}
      />
      <EmotionConfConfigItem
        label={"哀しみ"}
        value={props.currentConfig?.sorrow}
        default={SRW_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, sorrow: val });
        }}
      />
      <EmotionConfConfigItem
        label={"平常"}
        value={props.currentConfig?.calm}
        default={CLM_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, calm: val });
        }}
      />
    </div>
  );
};
