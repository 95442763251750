import { AnalysisV20Config } from "../lib";

const SES_STORAGE_TOKEN_KEY = "ACT-N";
const LOC_STORAGE_CONFIG_KEY = "AnalysisConfig";
const LOC_STORAGE_NOT_SAVE_FILE_NAME_KEY = "NotSaveFileName";

/**
 * アクセストークンを保存する
 *
 * @param accessToken
 */
export const saveAccessToken = (accessToken: string) => {
  localStorage.setItem(SES_STORAGE_TOKEN_KEY, accessToken);
};

/**
 * アクセストークンを読み込む
 */
export const loadAccessToken = (): string | null => {
  return localStorage.getItem(SES_STORAGE_TOKEN_KEY);
};

/**
 * アクセストークンの保存を削除する
 */
export const clearAccessToken = () => {
  localStorage.removeItem(SES_STORAGE_TOKEN_KEY);
};

/**
 * 解析設定を保存する
 */
export const saveAnalysisConfig = (config: AnalysisV20Config) => {
  localStorage.setItem(LOC_STORAGE_CONFIG_KEY, JSON.stringify(config));
};

/**
 * 解析設定を読み込む
 */
export const loadAnalysisConfig = (): AnalysisV20Config => {
  const tmpConfig = localStorage.getItem(LOC_STORAGE_CONFIG_KEY);
  return tmpConfig === null ? {} : (JSON.parse(tmpConfig) as AnalysisV20Config);
};

/**
 * ファイル名の非保存設定を保存する
 *
 * @param notSaveFileName
 */
export const saveNotSaveFileNameOption = (notSaveFileName: boolean) => {
  localStorage.setItem(
    LOC_STORAGE_NOT_SAVE_FILE_NAME_KEY,
    notSaveFileName.toString()
  );
};

/**
 * ファイル名の非保存設定を読み込む
 */
export const loadNotSaveFileNameOption = (): boolean => {
  const option = localStorage.getItem(LOC_STORAGE_NOT_SAVE_FILE_NAME_KEY);
  return option === null ? false : option.toLowerCase() === "true";
};
