import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import {
  AnalysisV20ConfigTextEmotion,
  AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum,
} from "../../../lib";
import { makeStyles } from "@material-ui/core/styles";

const SelectType = styled(Select)`
  width: 200px;
`;

const DivTranscriptionMargin = styled.div`
  margin-top: 30px;
`;

const TypographyChannelLabel = styled(Typography)`
  margin: auto 10px;
`;

const DivTranscriptionOfChannelWrapper = styled.div`
  margin: 15px 0;
  display: flex;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootMargin: {
      margin: theme.spacing(1),
    },
  })
);

type InputTranscriptionListOfChannelProps = {
  transcriptionListOfChannel: string[]; // チャンネルの書き起こしリスト
  setTranscriptionListOfChannel: (list: string[]) => void; // 書き起こし文一覧のセッター
};

/**
 * チャンネルの書き起こし一覧コンポーネント
 *
 * @constructor
 */
const InputTranscriptionListOfChannel: React.FC<InputTranscriptionListOfChannelProps> = (
  props: InputTranscriptionListOfChannelProps
) => {
  const generateTranscriptionValue = (list: string[]): string => {
    return list.join("\n");
  };

  const [newTranscription, setNewTranscription] = useState(
    generateTranscriptionValue(props.transcriptionListOfChannel)
  );
  useEffect(() => {
    const newValue = generateTranscriptionValue(
      props.transcriptionListOfChannel
    );
    setNewTranscription(newValue);
  }, [props.transcriptionListOfChannel]);

  return (
    <TextField
      label={"書き起こし文 (発話ごとに改行)"}
      value={newTranscription}
      multiline={true}
      fullWidth={true}
      inputProps={{ "data-testid": "transcriptionInputTest" }}
      onChange={(event) => setNewTranscription(event.target.value)}
      onBlur={() => {
        const newList = newTranscription.split("\n");
        props.setTranscriptionListOfChannel(newList);
      }}
    />
  );
};

type InputTranscriptionListProps = {
  transcriptionList?: string[][];
  setTranscriptionList: (list: string[][]) => void;
};

/**
 * 発話一覧の入力コンポーネント
 *
 * @param props
 * @constructor
 */
const InputTranscriptionList: React.FC<InputTranscriptionListProps> = (
  props: InputTranscriptionListProps
) => {
  const currentTranscriptionList = props.transcriptionList
    ? props.transcriptionList
    : [[]];

  return (
    <React.Fragment>
      {currentTranscriptionList.map(
        (channelTranscriptionList, channelIndex) => {
          const channelLabel = `Ch${channelIndex + 1}`;
          return (
            <DivTranscriptionOfChannelWrapper key={channelIndex}>
              {currentTranscriptionList.length > 1 && (
                <TypographyChannelLabel variant={"body2"}>
                  {channelLabel}
                </TypographyChannelLabel>
              )}
              <InputTranscriptionListOfChannel
                transcriptionListOfChannel={channelTranscriptionList}
                setTranscriptionListOfChannel={(newChannelList) => {
                  const newList = [...currentTranscriptionList];
                  newList[channelIndex] = newChannelList;
                  props.setTranscriptionList(newList);
                }}
              />
            </DivTranscriptionOfChannelWrapper>
          );
        }
      )}
      <Button
        startIcon={<AddIcon />}
        onClick={() => {
          const newList = [...currentTranscriptionList];
          newList.push([]);
          props.setTranscriptionList(newList);
        }}
        data-testid={"addChannelButtonTest"}
      >
        チャンネルを増やす
      </Button>
    </React.Fragment>
  );
};

type SpeechRecognitionConfigProps = {
  currentTextEmotionConfig?: AnalysisV20ConfigTextEmotion; // 現在の音声認識設定
  setTextEmotionConfig: (config: AnalysisV20ConfigTextEmotion) => void; // 設定のセッター
};

/**
 * 音声認識設定
 *
 * @param props
 * @constructor
 */
export const TextEmotionConfig: React.FC<SpeechRecognitionConfigProps> = (
  props: SpeechRecognitionConfigProps
): ReactElement => {
  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type = event.target
      .value as AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum;
    props.setTextEmotionConfig({
      ...props.currentTextEmotionConfig,
      speechRecognitionType: type,
    });
  };
  const classes = useStyles();
  const currentType = props.currentTextEmotionConfig
    ? props.currentTextEmotionConfig.speechRecognitionType
    : AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum.Google;

  return (
    <FormControl className={classes.rootMargin} fullWidth={true}>
      <InputLabel>音声認識種別</InputLabel>
      <SelectType
        value={currentType}
        onChange={handleChangeType}
        label={"音声認識種別"}
        inputProps={{
          "data-testid": "select-speech-recognition-type",
        }}
      >
        <MenuItem
          value={AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum.Google}
        >
          Google Speech-to-Text
        </MenuItem>
        <MenuItem
          value={AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum.Text}
        >
          テキスト入力
        </MenuItem>
        <MenuItem
          value={AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum.Disable}
        >
          無効
        </MenuItem>
      </SelectType>
      {currentType ===
        AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum.Text && (
        <React.Fragment>
          <DivTranscriptionMargin />
          <InputTranscriptionList
            transcriptionList={
              props.currentTextEmotionConfig?.transcriptionList
            }
            setTranscriptionList={(list) => {
              props.setTextEmotionConfig({
                ...props.currentTextEmotionConfig,
                transcriptionList: list,
              });
            }}
          />
        </React.Fragment>
      )}
    </FormControl>
  );
};
