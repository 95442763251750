enum ErrorCode {
  AuthorizationError = 3000,
  LicenseIsExpired,
  UsageLimitIsExceeded,
  InvalidContentType,
  ExceededPayloadSize,
  PayloadIsInvalid,
  Emotion8ThresholdIsInvalid,
  Emotion4ThresholdIsInvalid,
  AudioBase64EncodeIsInvalid,
  UnsupportedAudioType,
  StartSpanIsInvalid,
  EndSpanIsInvalid,
  DetectVolumeIsInvalid,
  MaxTimeIsInvalid,
  StartMarginIsInvalid,
  SomeVadConfigIsConflict,
  UnexpectedError = 3999,
}

type AnalysisError = {
  errorCode: number;
  detail: string;
};

/**
 * 解析エラーの詳細を取得する
 *
 * @param error 解析エラー
 */
export const getAnalysisErrorDescription = (error: AnalysisError): string => {
  switch (error.errorCode) {
    case ErrorCode.AuthorizationError:
      return "ユーザ認可に失敗しました。再ログインしてください。";
    case ErrorCode.LicenseIsExpired:
      return "ユーザライセンスの有効期限がきれています。";
    case ErrorCode.UsageLimitIsExceeded:
      return "解析回数の上限を超過しています。";
    case ErrorCode.ExceededPayloadSize:
      return "解析対象の音声ファイルサイズが大きすぎます。7MBまでの音声ファイルにしてください";
    case ErrorCode.Emotion8ThresholdIsInvalid:
      return "8感情の確定感情閾値が不正です。";
    case ErrorCode.Emotion4ThresholdIsInvalid:
      return "4感情の確定感情閾値が不正です。";
    case ErrorCode.UnsupportedAudioType:
      return "対応していない音声ファイル種別です。";
    case ErrorCode.StartSpanIsInvalid:
      return "発話検知設定の発話検知区間長の値が不正です。";
    case ErrorCode.EndSpanIsInvalid:
      return "発話検知設定の発話終了判定区間長の値が不正です。";
    case ErrorCode.DetectVolumeIsInvalid:
      return "発話検知設定の発話検知ボリュームの値が不正です。";
    case ErrorCode.MaxTimeIsInvalid:
      return "発話検知設定の1発話の最大長の値が不正です。";
    case ErrorCode.StartMarginIsInvalid:
      return "発話検知設定の発話検知巻き戻し時間の値が不正です。";
    case ErrorCode.SomeVadConfigIsConflict:
      return "発話検知設定のいくつかの設定値が競合しています。";
    default:
      return "解析に失敗しました。";
  }
};
