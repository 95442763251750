import React, { ReactElement } from "react";
import { VoiceEmotion8ConfThresholds } from "../../../lib";
import { EmotionConfConfigItem } from "./EmotionConfConfigItem";

const JOY1_DEFAULT_THRESHOLD = 60;
const JOY2_DEFAULT_THRESHOLD = 57;
const ANG1_DEFAULT_THRESHOLD = 51;
const ANG2_DEFAULT_THRESHOLD = 56;
const ANG3_DEFAULT_THRESHOLD = 50;
const SRW1_DEFAULT_THRESHOLD = 41;
const SRW2_DEFAULT_THRESHOLD = 47;
const CLM_DEFAULT_THRESHOLD = 47;

type Emotion8ConfThresholdConfigProps = {
  currentConfig: VoiceEmotion8ConfThresholds | undefined; // 現在の設定値
  configSetter: (threshold: VoiceEmotion8ConfThresholds) => void; // 設定値のセッター
};

/**
 * 確定感情閾値の設定画面
 *
 * @param props
 * @constructor
 */
export const Emotion8ConfThresholdConfig: React.FC<Emotion8ConfThresholdConfigProps> = (
  props: Emotion8ConfThresholdConfigProps
): ReactElement => {
  return (
    <div>
      <EmotionConfConfigItem
        label={"喜び1"}
        value={props.currentConfig?.joy1}
        default={JOY1_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, joy1: val });
        }}
      />
      <EmotionConfConfigItem
        label={"喜び2"}
        value={props.currentConfig?.joy2}
        default={JOY2_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, joy2: val });
        }}
      />
      <EmotionConfConfigItem
        label={"怒り1"}
        value={props.currentConfig?.anger1}
        default={ANG1_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, anger1: val });
        }}
      />
      <EmotionConfConfigItem
        label={"怒り2"}
        value={props.currentConfig?.anger2}
        default={ANG2_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, anger2: val });
        }}
      />
      <EmotionConfConfigItem
        label={"怒り3"}
        value={props.currentConfig?.anger3}
        default={ANG3_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, anger3: val });
        }}
      />
      <EmotionConfConfigItem
        label={"哀しみ1"}
        value={props.currentConfig?.sorrow1}
        default={SRW1_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, sorrow1: val });
        }}
      />
      <EmotionConfConfigItem
        label={"哀しみ2"}
        value={props.currentConfig?.sorrow2}
        default={SRW2_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, sorrow2: val });
        }}
      />
      <EmotionConfConfigItem
        label={"平常"}
        value={props.currentConfig?.calm}
        default={CLM_DEFAULT_THRESHOLD}
        newValueSetter={(val: number) => {
          props.configSetter({ ...props.currentConfig, calm: val });
        }}
      />
    </div>
  );
};
