import React, { ReactElement } from "react";
import { TopPageLayout } from "./TopPageLayout";
import { Typography } from "@material-ui/core";

export const NoPageError: React.FC = (): ReactElement => {
  return (
    <TopPageLayout title={"404 Not Found"}>
      <Typography align={"center"} variant={"h5"}>
        お探しのページが見つかりません。
      </Typography>
    </TopPageLayout>
  );
};
