import React, { ReactElement, useMemo, useRef, useState } from "react";
import { SpeechListTabView } from "./SpeechListTable";
import { EmotionTimeLine } from "./EmotionTimeLine";
import styled from "styled-components";
import { ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN } from "./AnalysisResultStyles";
import {
  AnalysisV20Config,
  AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum,
  AnalysisV20Result,
  AnalysisV20ResultUtteranceList,
} from "../../../lib";
import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { convertAnalysisResultToCsv } from "../../../utils/utility";
import { makeStyles } from "@material-ui/core/styles";
import {
  postAnalysis,
  reflectNotSaveFileNameOptionToConfig,
} from "../../../utils/post";
import { getAnalysisErrorDescription } from "../AnalysisRequest/AnalysisError";
import { loadNotSaveFileNameOption } from "../../../utils/storage";

const DivContentMargin = styled.div`
  height: 10px;
`;

const FormControlStyled = styled(FormControl)`
  min-width: 150px;
`;

const DivReAnalysisWrapper = styled.div`
  padding: 5px ${ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN}px 0;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    errorMessage: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    resultHeaderWrapper: {
      padding: `0 ${ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN}px`,
      marginBottom: "10px",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    audio: {
      maxWidth: "300px",
      width: "100%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

/**
 * 結果保存種別
 */
enum DownloadDataType {
  csv,
  image,
}

/**
 * 再解析用の解析設定を作る
 *
 * @param oldConfig 初回解析時の設定
 * @param utteranceList 発話一覧
 */
const generateReAnalysisConfig = (
  oldConfig: AnalysisV20Config,
  utteranceList: AnalysisV20ResultUtteranceList[]
): AnalysisV20Config => {
  const transcriptionList: string[][] = [[]];
  for (const utterance of utteranceList) {
    let channel: number;
    if (utterance.channelId === 0) {
      channel = 0;
    } else {
      channel = utterance.channelId - 1;
    }
    let transcription =
      utterance.textEmotion === undefined
        ? ""
        : utterance.textEmotion.transcription;
    if (transcriptionList[channel] !== undefined) {
      transcriptionList[channel].push(transcription);
    } else {
      transcriptionList[channel] = [transcription];
    }
  }

  return {
    ...oldConfig,
    textEmotion: {
      ...oldConfig.textEmotion,
      speechRecognitionType:
        AnalysisV20ConfigTextEmotionSpeechRecognitionTypeEnum.Text,
      transcriptionList: transcriptionList,
    },
  };
};

/**
 * 解析結果画面の引数
 */
type AnalysisResultProps = {
  result: AnalysisV20Result; // 解析レスポンス
  config: AnalysisV20Config; // 解析設定
  loadingFile: File; // 解析を行った音声データ
  accessToken: string; // アクセストークン
  setResult: React.Dispatch<AnalysisV20Result>; // 再解析時の結果セッター
};

/**
 * 解析結果画面
 *
 * @param props
 * @constructor
 */
export const AnalysisResult: React.FC<AnalysisResultProps> = (
  props: AnalysisResultProps
): ReactElement => {
  const classes = useStyles();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [errorDescription, setErrorDescription] = useState<string>();
  const [timeLineImage, setTimeLineImage] = useState<string | null>(null);
  const [downloadDialogIsOpen, setDownloadDialogIsOpen] = useState(false);
  const [downloadDataType, setDownloadDataType] = useState(
    DownloadDataType.csv
  );
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioUrl = useMemo(() => {
    return URL.createObjectURL(props.loadingFile);
  }, [props.loadingFile]);

  const generateDownloadFileName = (extension: string): string => {
    const splitName = props.loadingFile.name.split(".");
    splitName[splitName.length - 1] = extension;
    return splitName.join(".");
  };

  const handleDownloadResultImage = () => {
    if (timeLineImage !== null) {
      const aElem = document.createElement("a");
      aElem.href = timeLineImage;
      aElem.download = generateDownloadFileName("jpg");
      aElem.click();
    }
  };

  const handleDownloadCsv = () => {
    const aElem = document.createElement("a");
    const csv = convertAnalysisResultToCsv(props.result);
    aElem.href = URL.createObjectURL(new Blob([csv], { type: "text/plain" }));
    aElem.download = generateDownloadFileName("csv");
    aElem.click();
  };

  const reAnalyze = () => {
    setShowLoadingModal(true);
    let config: AnalysisV20Config;
    const notToSaveFileName = loadNotSaveFileNameOption();
    const reflectedResult = reflectNotSaveFileNameOptionToConfig(
      props.config,
      notToSaveFileName,
      props.loadingFile
    );
    if (typeof reflectedResult === "string") {
      setErrorDescription(reflectedResult);
      return;
    } else {
      config = reflectedResult;
    }

    const reanalysisConfig = generateReAnalysisConfig(
      config,
      props.result.utteranceList
    );
    postAnalysis(props.loadingFile, props.accessToken, reanalysisConfig)
      .then((response) => {
        setShowLoadingModal(false);
        props.setResult(response);
      })
      .catch((e) => {
        setShowLoadingModal(false);
        console.error("[Request Reanalysis Error]", e);
        if (e.response && e.response.data) {
          console.error("[Request Reanalysis Error Response]", e.response.data);
          const description = getAnalysisErrorDescription(e.response.data);
          setErrorDescription(description);
        } else {
          setErrorDescription("解析に失敗しました");
        }
      });
  };

  return (
    <div>
      {errorDescription && (
        <Typography
          variant={"body1"}
          align={"center"}
          color={"error"}
          className={classes.errorMessage}
        >
          {errorDescription}
        </Typography>
      )}
      {audioUrl && (
        <Paper className={classes.paper}>
          <div className={classes.resultHeaderWrapper}>
            <audio
              className={classes.audio}
              ref={audioRef}
              src={audioUrl}
              controls={true}
              preload={"metadata"}
            />
            <Button
              variant={"contained"}
              size={"large"}
              startIcon={<CloudDownload />}
              disableElevation={true}
              onClick={() => setDownloadDialogIsOpen(true)}
            >
              解析結果をダウンロード
            </Button>
          </div>
          <EmotionTimeLine
            config={props.config}
            utterances={props.result.utteranceList}
            audio={audioRef}
            imageSetter={setTimeLineImage}
            loadingFile={props.loadingFile}
          />
          <DivContentMargin />
          <SpeechListTabView
            utterances={props.result.utteranceList}
            audio={audioRef}
            setUtteranceList={(newList: AnalysisV20ResultUtteranceList[]) => {
              props.setResult({ ...props.result, utteranceList: newList });
            }}
          />
          <DivReAnalysisWrapper>
            <Button
              variant={"contained"}
              size={"small"}
              disableElevation={true}
              onClick={reAnalyze}
            >
              現在の発話内容で再解析を行う
            </Button>
          </DivReAnalysisWrapper>
        </Paper>
      )}
      <Backdrop
        className={classes.backdrop}
        open={showLoadingModal}
        onClick={(e) => e.preventDefault()}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={downloadDialogIsOpen}
        onClose={() => setDownloadDialogIsOpen(false)}
      >
        <DialogTitle>解析結果の保存</DialogTitle>
        <DialogContent>
          <FormControlStyled>
            <InputLabel>保存データ種別</InputLabel>
            <Select
              value={downloadDataType}
              onChange={(e) =>
                setDownloadDataType(e.target.value as DownloadDataType)
              }
            >
              <MenuItem value={DownloadDataType.csv}>CSV</MenuItem>
              {timeLineImage !== null && (
                <MenuItem value={DownloadDataType.image}>画像</MenuItem>
              )}
            </Select>
          </FormControlStyled>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDownloadDialogIsOpen(false)}
            color="primary"
          >
            キャンセル
          </Button>
          <Button
            onClick={() => {
              switch (downloadDataType) {
                case DownloadDataType.image:
                  handleDownloadResultImage();
                  break;
                case DownloadDataType.csv:
                  handleDownloadCsv();
                  break;
              }
              setDownloadDialogIsOpen(false);
            }}
            color={"primary"}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
