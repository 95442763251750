import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AppBar,
  Box,
  createStyles,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AccountBox,
  Dashboard as DashboardIcon,
  Description,
  ExitToApp,
  Home,
  Menu,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  LicenseInfo,
  LOGO_SRC_PATH,
  RoutingPaths,
  UserAnalysisPaths,
  UserManagementPaths,
  UserRoutingPaths,
  WEB_DESCRIPTION_PHRASE,
  WEB_SUB_DESCRIPTION_PHRASE,
} from "../../CommonTypes";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Copyright } from "../Copyright";
import { LogOutDialog } from "./LogOutDialog";
import { UserManagement } from "./UserManagement";
import { PasswordEditor } from "./PasswordEditor";
import { BreadcrumbsOfUserMyPage } from "./BreadcrumbsOfUserMyPage";
import { AnalysisRequest } from "./AnalysisRequest/AnalysisRequest";
import { AnalysisV20Config, AnalysisV20Result } from "../../lib";
import { AnalysisResult } from "./AnalysisResult/AnalysisResult";
import { basePathOfApiServer } from "../../utils/utility";
import { Dashboard } from "./Dashboard";
import axios from "axios";
import { loadAccessToken } from "../../utils/storage";
import { Documents } from "./Documents";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    noDecorationText: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    appBar: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    toolbar: {
      flexDirection: "row",
      margin: "0.5em 0",
    },
    toolbarOffset: theme.mixins.toolbar,
    titleWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    logoImg: {
      width: 160,
    },
    content: {
      overflow: "auto",
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    breadcrumbsWrapper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    errorMessage: {
      margin: theme.spacing(2),
    },
  })
);

const LICENSE_PATH = "/st-cloud/v2.0/license";

/**
 * ユーザのライセンス情報をサーバに問い合わせる
 *
 * @param accessToken ST Cloudのアクセストークン
 */
const getUserLicenseInfo = async (
  accessToken: string
): Promise<LicenseInfo | null> => {
  try {
    const uri = basePathOfApiServer() + LICENSE_PATH;
    let response = await axios.get(uri, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data as LicenseInfo;
  } catch (e) {
    console.error("[Get LicenseInfo Error]", e);
    return null;
  }
};

type SideBarListItemProps = {
  currentPath: UserRoutingPaths; // 現在のパス
  itemRouting: UserRoutingPaths; // リストのアイテムクリックで移動するパス
  icon: ReactElement; // 表示アイコン
  label: string; // 表示文字列
  closeDrawer: () => void; // リンククリック時にドロワーを閉じる
};

/**
 * サイドバーの要素コンポーネント
 *
 * @param props
 * @constructor
 */
const SideBarListItem: React.FC<SideBarListItemProps> = (
  props: SideBarListItemProps
): ReactElement => {
  const classes = useStyles();

  return (
    <Link
      to={RoutingPaths.userMyPage + props.itemRouting}
      className={classes.noDecorationText}
      onClick={props.closeDrawer}
    >
      <ListItem
        button={true}
        selected={props.currentPath === props.itemRouting}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItem>
    </Link>
  );
};

// アクセストークンの有効時間（ミリ秒）
const ACCESS_TOKEN_LIMIT_MILLI_SEC = 60 * 60 * 1000;

/**
 * ユーザページ表示管理コンポーネントの引数
 */
type UserPageProps = {
  routing: UserRoutingPaths; // 現在のパス
  accessToken: string; // アクセストークン
  subRouting?: UserManagementPaths | UserAnalysisPaths; // 現在のサブパス(ある場合)
};

/**
 * ユーザページ表示管理コンポーネント
 *
 * @constructor
 */
export const UserMyPage: React.FC<UserPageProps> = (
  props: UserPageProps
): ReactElement => {
  const classes = useStyles();
  const routeHistory = useHistory();
  const theme = useTheme();
  const screenIsMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [analysisFile, setAnalysisFile] = useState<File | null>(null);
  const [
    analysisResponse,
    setAnalysisResponse,
  ] = useState<AnalysisV20Result | null>(null);
  const [analysisConfig, setAnalysisConfig] = useState<AnalysisV20Config>({});
  const [licenseInfo, setLicenseInfo] = useState<LicenseInfo>();
  const [tokenIsExpired, setTokenIsExpired] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const handleDrawerToggle = () => setDrawerIsOpen(!drawerIsOpen);
  const closeDrawer = useCallback(() => setDrawerIsOpen(false), []);

  // ライセンス情報取得
  useEffect(() => {
    getUserLicenseInfo(props.accessToken).then((info) => {
      if (info) {
        setLicenseInfo(info);
      } else {
        routeHistory.push(RoutingPaths.login);
      }
    });
  }, [props.accessToken, routeHistory]);

  // アクセストークンの期限が切れたら再ログインを促す
  useEffect(() => {
    const id = setTimeout(() => {
      setTokenIsExpired(true);
    }, ACCESS_TOKEN_LIMIT_MILLI_SEC);
    return () => clearTimeout(id);
  }, []);

  const drawer = useMemo(
    () => (
      <React.Fragment>
        <IconButton component={Link} to={RoutingPaths.top}>
          <img className={classes.logoImg} src={LOGO_SRC_PATH} alt={"logo"} />
        </IconButton>
        <Divider />
        <List>
          <SideBarListItem
            currentPath={props.routing}
            itemRouting={UserRoutingPaths.analysis}
            icon={<Home />}
            label={"ST Cloud WEB App"}
            closeDrawer={closeDrawer}
          />
          <SideBarListItem
            currentPath={props.routing}
            itemRouting={UserRoutingPaths.dashboard}
            icon={<DashboardIcon />}
            label={"ダッシュボード"}
            closeDrawer={closeDrawer}
          />
          <SideBarListItem
            currentPath={props.routing}
            itemRouting={UserRoutingPaths.management}
            icon={<AccountBox />}
            label={"ユーザ管理"}
            closeDrawer={closeDrawer}
          />
          <SideBarListItem
            currentPath={props.routing}
            itemRouting={UserRoutingPaths.documents}
            icon={<Description />}
            label={"ドキュメント"}
            closeDrawer={closeDrawer}
          />
          <SideBarListItem
            currentPath={props.routing}
            itemRouting={UserRoutingPaths.logout}
            icon={<ExitToApp />}
            label={"ログアウト"}
            closeDrawer={closeDrawer}
          />
        </List>
      </React.Fragment>
    ),
    [classes.logoImg, closeDrawer, props.routing]
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <div className={classes.titleWrapper}>
            <Typography variant={screenIsMobile ? "h6" : "h4"} align={"center"}>
              {WEB_DESCRIPTION_PHRASE}
            </Typography>
            <Typography
              variant={screenIsMobile ? "subtitle1" : "h6"}
              align={"center"}
            >
              {WEB_SUB_DESCRIPTION_PHRASE}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Hidden mdUp={true}>
        <Drawer
          className={classes.drawer}
          variant={"temporary"}
          open={drawerIsOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown={true}>
        <Drawer
          className={classes.drawer}
          variant={"permanent"}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbarOffset} />
        <div className={classes.breadcrumbsWrapper}>
          <BreadcrumbsOfUserMyPage
            userRouting={props.routing}
            subRouting={props.subRouting}
          />
        </div>
        {tokenIsExpired && (
          <Typography
            variant={"body1"}
            align={"center"}
            color={"error"}
            className={classes.errorMessage}
          >
            セッションの有効期限が切れました。再ログインしてください。
          </Typography>
        )}
        {props.routing === UserRoutingPaths.analysis &&
          props.subRouting === undefined && (
            <AnalysisRequest
              targetFileSetter={setAnalysisFile}
              responseSetter={setAnalysisResponse}
              configSetter={setAnalysisConfig}
              accessToken={props.accessToken}
            />
          )}
        {props.routing === UserRoutingPaths.analysis &&
          props.subRouting === UserAnalysisPaths.result &&
          analysisResponse &&
          analysisFile && (
            <AnalysisResult
              result={analysisResponse}
              config={analysisConfig}
              loadingFile={analysisFile}
              accessToken={props.accessToken}
              setResult={setAnalysisResponse}
            />
          )}
        {props.routing === UserRoutingPaths.dashboard && (
          <Dashboard
            accessToken={props.accessToken}
            accountCreatedAt={licenseInfo?.createdAt}
          />
        )}
        {props.routing === UserRoutingPaths.management &&
          props.subRouting === undefined && (
            <UserManagement
              accessToken={props.accessToken}
              licenseInfo={licenseInfo}
            />
          )}
        {props.routing === UserRoutingPaths.management &&
          props.subRouting === UserManagementPaths.changePassword && (
            <PasswordEditor accessToken={props.accessToken} />
          )}
        {props.routing === UserRoutingPaths.documents && <Documents />}
        {props.routing === UserRoutingPaths.logout && <LogOutDialog />}
        <Box mt={8}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
};

/**
 * マイページの各ページへのルーティング
 *
 * @constructor
 */
export const UserMyPageRouting: React.FC = (): ReactElement => {
  const accessToken = loadAccessToken();
  if (accessToken === null) {
    return <Redirect to={{ pathname: RoutingPaths.login }} />;
  }

  return (
    <Switch>
      <Route
        exact={true}
        path={RoutingPaths.userMyPage + UserRoutingPaths.analysis}
      >
        <UserMyPage
          routing={UserRoutingPaths.analysis}
          accessToken={accessToken}
        />
      </Route>
      <Route
        exact={true}
        path={
          RoutingPaths.userMyPage +
          UserRoutingPaths.analysis +
          UserAnalysisPaths.result
        }
      >
        <UserMyPage
          routing={UserRoutingPaths.analysis}
          accessToken={accessToken}
          subRouting={UserAnalysisPaths.result}
        />
      </Route>
      <Route
        exact={true}
        path={RoutingPaths.userMyPage + UserRoutingPaths.dashboard}
      >
        <UserMyPage
          routing={UserRoutingPaths.dashboard}
          accessToken={accessToken}
        />
      </Route>
      <Route
        exact={true}
        path={RoutingPaths.userMyPage + UserRoutingPaths.management}
      >
        <UserMyPage
          routing={UserRoutingPaths.management}
          accessToken={accessToken}
        />
      </Route>
      <Route
        exact={true}
        path={
          RoutingPaths.userMyPage +
          UserRoutingPaths.management +
          UserManagementPaths.changePassword
        }
      >
        <UserMyPage
          routing={UserRoutingPaths.management}
          accessToken={accessToken}
          subRouting={UserManagementPaths.changePassword}
        />
      </Route>
      <Route
        exact={true}
        path={RoutingPaths.userMyPage + UserRoutingPaths.logout}
      >
        <UserMyPage
          routing={UserRoutingPaths.logout}
          accessToken={accessToken}
        />
      </Route>
      <Route
        exact={true}
        path={RoutingPaths.userMyPage + UserRoutingPaths.documents}
      >
        <UserMyPage
          routing={UserRoutingPaths.documents}
          accessToken={accessToken}
        />
      </Route>
      <Route>
        <Redirect to={{ pathname: RoutingPaths.noPage }} />
      </Route>
    </Switch>
  );
};
