import React, { PropsWithChildren, ReactElement } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  createStyles,
  CssBaseline,
  IconButton,
  Link as MuiLink,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  API_DOCUMENT_PATH,
  LOGO_SRC_PATH,
  RoutingPaths,
} from "../../CommonTypes";
import { Copyright } from "../Copyright";
import { makeStyles } from "@material-ui/core/styles";
import { Launch, MoreVert } from "@material-ui/icons";

const SUPPORT_URL = "https://www.agi-web.co.jp/contact/";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoWrapper: {
      flexGrow: 1,
    },
    logoImg: {
      height: 30,
      [theme.breakpoints.up("sm")]: {
        height: 40,
      },
      [theme.breakpoints.up("md")]: {
        height: 50,
      },
    },
    link: {
      fontWeight: "normal",
      width: "100%",
      margin: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(0, 0.9),
      },
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0, 1.5),
      },
    },
    launchIcon: {
      marginLeft: 3,
      verticalAlign: "middle",
    },
    navWrapper: {
      marginRight: theme.spacing(0.8),
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(3),
      },
    },
    mobileMenuWrapper: {
      display: "display",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    content: {
      padding: theme.spacing(8, 0, 6),
    },
    title: {
      marginBottom: theme.spacing(8),
    },
  })
);

type HeaderLinkProps = {
  title: string;
  to: string;
};

/**
 * 同一タブ内で遷移するリンク
 *
 * @param props
 * @constructor
 */
const HeaderLinkItem: React.FC<HeaderLinkProps> = (
  props: HeaderLinkProps
): ReactElement => {
  const classes = useStyles();
  return (
    <MuiLink
      className={classes.link}
      component={Link}
      variant={"button"}
      color={"textPrimary"}
      to={props.to}
    >
      {props.title}
    </MuiLink>
  );
};

type HeaderNewTabLinkItemProps = {
  title: string; // 表示名
  href: string; // リンク先
};

/**
 * 新規タブで開くリンク
 *
 * @param props
 * @constructor
 */
const HeaderNewTabLinkItem: React.FC<HeaderNewTabLinkItemProps> = (
  props: HeaderNewTabLinkItemProps
): ReactElement => {
  const classes = useStyles();

  return (
    <MuiLink
      className={classes.link}
      variant={"button"}
      color={"textPrimary"}
      href={props.href}
      target={"_blank"}
      rel={"noopener"}
    >
      {props.title}
      {<Launch className={classes.launchIcon} fontSize={"inherit"} />}
    </MuiLink>
  );
};

type TopPageLayoutProps = {
  title: string;
};

/**
 * トップページのレイアウトコンポーネント
 *
 * @param props
 * @constructor
 */
export const TopPageLayout: React.FC<TopPageLayoutProps> = (
  props: PropsWithChildren<TopPageLayoutProps>
): ReactElement => {
  const classes = useStyles();
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const screenIsMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const mobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={!!mobileMoreAnchorEl}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <HeaderLinkItem title={"特徴"} to={RoutingPaths.top} />
      </MenuItem>
      <MenuItem>
        <HeaderLinkItem title={"料金"} to={RoutingPaths.pricing} />
      </MenuItem>
      <MenuItem>
        <HeaderNewTabLinkItem title={"API"} href={API_DOCUMENT_PATH} />
      </MenuItem>
      <MenuItem>
        <HeaderNewTabLinkItem title={"お問い合わせ"} href={SUPPORT_URL} />
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <div className={classes.logoWrapper}>
            <IconButton component={Link} to={RoutingPaths.top}>
              <img
                src={LOGO_SRC_PATH}
                alt={"logo"}
                className={classes.logoImg}
              />
            </IconButton>
          </div>
          <nav className={classes.navWrapper}>
            <HeaderLinkItem title={"特徴"} to={RoutingPaths.top} />
            <HeaderLinkItem title={"料金"} to={RoutingPaths.pricing} />
            <HeaderNewTabLinkItem title={"API"} href={API_DOCUMENT_PATH} />
            <HeaderNewTabLinkItem title={"お問い合わせ"} href={SUPPORT_URL} />
          </nav>
          <Button
            component={Link}
            to={RoutingPaths.login}
            color={"primary"}
            variant={"outlined"}
            size={screenIsMobile ? "small" : "medium"}
          >
            ログイン / 利用登録
          </Button>
          <div className={classes.mobileMenuWrapper}>
            <IconButton onClick={handleMobileMenuOpen}>
              <MoreVert />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {mobileMenu}

      {/* FIXME: リリース時、テスト中の文言を削除する */}
      <Typography variant={"h5"} align={"center"} color={"error"}>
        (アルファテスト中)
      </Typography>
      <Container className={classes.content} component={"main"}>
        <Typography
          component={"h1"}
          variant={"h3"}
          align={"center"}
          color={"textPrimary"}
          gutterBottom={true}
          className={classes.title}
        >
          {props.title}
        </Typography>
        <React.Fragment>{props.children}</React.Fragment>
      </Container>

      <Box mt={8} mb={2}>
        <Copyright />
      </Box>
    </React.Fragment>
  );
};
