import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { LogIn } from "./View/LogIn";
import { PasswordRestPaths, RoutingPaths } from "./CommonTypes";
import { Features } from "./View/Top/Features";
import { UserMyPageRouting } from "./View/UserMyPage/UserMyPage";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { ApplyReset } from "./View/PasswordReset/ApplyReset";
import { SentResetEmail } from "./View/PasswordReset/SentResetEmail";
import { NewPassword } from "./View/PasswordReset/NewPassword";
import { ChangedPassword } from "./View/PasswordReset/ChangedPassword";
import { Pricing } from "./View/Top/Pricing";
import { NoPageError } from "./View/Top/NoPageError";
import { useTracking } from "./utils/tracking";

const primaryBase = {
  main: "#42a5f5",
  light: "#80d6ff",
  dark: "#0077c2",
  contrastText: "#fff",
};

const secondaryBase = {
  main: "#d32f2f",
  light: "#ff6659",
  dark: "#9a0007",
  contrastText: "#fff",
};

const outerLightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: primaryBase,
    secondary: secondaryBase,
  },
});

const innerLightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3e2723",
      light: "#6a4f4b",
      dark: "#1b0000",
      contrastText: "#fff",
    },
    secondary: secondaryBase,
  },
});

const RouterWithTracking = (): ReactElement => {
  useTracking();

  return (
    <Switch>
      <Route exact={true} path={RoutingPaths.top}>
        <Features />
      </Route>
      <Route exact={true} path={RoutingPaths.pricing}>
        <Pricing />
      </Route>
      <Route exact={true} path={RoutingPaths.login}>
        <LogIn />
      </Route>
      {/* FIXME: 以下新規登録停止中のためコメントアウト */}
      {/*<Route path={RoutingPaths.signUp}>*/}
      {/*  <Switch>*/}
      {/*    <Route*/}
      {/*      exact={true}*/}
      {/*      path={RoutingPaths.signUp + SignUpRoutingPaths.input}*/}
      {/*    >*/}
      {/*      <SignUp />*/}
      {/*    </Route>*/}
      {/*    <Route*/}
      {/*      exact={true}*/}
      {/*      path={RoutingPaths.signUp + SignUpRoutingPaths.sentEmail}*/}
      {/*    >*/}
      {/*      <SentEmail />*/}
      {/*    </Route>*/}
      {/*    <Route*/}
      {/*      exact={true}*/}
      {/*      path={RoutingPaths.signUp + SignUpRoutingPaths.confirmedEmail}*/}
      {/*    >*/}
      {/*      <ConfirmedEmailAddress />*/}
      {/*    </Route>*/}
      {/*    <Route*/}
      {/*      exact={true}*/}
      {/*      path={RoutingPaths.signUp + SignUpRoutingPaths.emailError}*/}
      {/*    >*/}
      {/*      <EmailAddressError />*/}
      {/*    </Route>*/}
      {/*    <Route>*/}
      {/*      <Redirect to={{ pathname: RoutingPaths.noPage }} />*/}
      {/*    </Route>*/}
      {/*  </Switch>*/}
      {/*</Route>*/}
      <Route path={RoutingPaths.userMyPage}>
        <MuiThemeProvider theme={innerLightTheme}>
          <UserMyPageRouting />
        </MuiThemeProvider>
      </Route>
      <Route path={RoutingPaths.passwordReset}>
        <Switch>
          <Route
            exact={true}
            path={RoutingPaths.passwordReset + PasswordRestPaths.application}
          >
            <ApplyReset />
          </Route>
          <Route
            exact={true}
            path={RoutingPaths.passwordReset + PasswordRestPaths.sentResetEmail}
          >
            <SentResetEmail />
          </Route>
          <Route
            exact={true}
            path={RoutingPaths.passwordReset + PasswordRestPaths.newPassword}
          >
            <NewPassword />
          </Route>
          <Route
            exact={true}
            path={
              RoutingPaths.passwordReset + PasswordRestPaths.changedPassword
            }
          >
            <ChangedPassword />
          </Route>
          <Route>
            <Redirect to={{ pathname: RoutingPaths.noPage }} />
          </Route>
        </Switch>
      </Route>
      <Route exact={true} path={RoutingPaths.noPage}>
        <NoPageError />
      </Route>
      <Route>
        <Redirect to={{ pathname: RoutingPaths.noPage }} />
      </Route>
    </Switch>
  );
};

const Root: React.FC = (): ReactElement => {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={outerLightTheme}>
        <RouterWithTracking />
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
