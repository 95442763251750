import React from "react";
import { createStyles, TextField, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const DEFAULT_SUFFIX_INTONATION_THRESHOLD = 5.0;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      margin: theme.spacing(1),
      width: "25ch",
    },
  })
);

type SuffixIntonationConfigProps = {
  currentThreshold?: number; // 現在の語尾抑揚閾値の値
  thresholdSetter: (threshold: number) => void; // 語尾抑揚閾値のセッター
};

/**
 * 語尾抑揚の設定画面
 *
 * @constructor
 */
export const SuffixIntonationConfig: React.FC<SuffixIntonationConfigProps> = (
  props: SuffixIntonationConfigProps
) => {
  const classes = useStyles();

  const title = (
    <React.Fragment>
      デフォルト値: {DEFAULT_SUFFIX_INTONATION_THRESHOLD}
    </React.Fragment>
  );

  return (
    <Tooltip title={title}>
      <TextField
        className={classes.text}
        type={"number"}
        label={"語尾抑揚閾値"}
        inputProps={{ step: 5.0, style: { textAlign: "center" } }}
        value={
          props.currentThreshold !== undefined
            ? props.currentThreshold
            : DEFAULT_SUFFIX_INTONATION_THRESHOLD
        }
        onChange={(event) => {
          const newVal = Number(event.target.value);
          props.thresholdSetter(newVal);
        }}
      />
    </Tooltip>
  );
};
