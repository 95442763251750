import React, { ReactElement } from "react";
import { TopPageLayout } from "./TopPageLayout";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import makeStyles from "@material-ui/core/styles/makeStyles";

const DivPriceWrapper = styled.div`
  margin-bottom: 20px;
`;

const CardStyled = styled(Card)`
  height: 100%;
`;

const DivPriceItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  grid: {
    width: "100%",
    margin: 0,
  },
}));

type PriceItemProps = {
  price: number;
  unit: string;
};

const PriceItem: React.FC<PriceItemProps> = (
  props: PriceItemProps
): ReactElement => {
  return (
    <DivPriceItem>
      <Typography component="h2" variant="h4" color="textPrimary">
        {props.price.toLocaleString()}
      </Typography>
      <Typography variant="h6" color="textSecondary">
        {props.unit}
      </Typography>
    </DivPriceItem>
  );
};

type Plan = {
  title: string;
  price: ReactElement;
  description: string;
  li?: string[];
};

const plans: Plan[] = [
  {
    title: "無料プラン",
    description: "ユーザ登録し、持っている音声ファイルを解析できる。",
    price: <PriceItem price={0} unit={"円"} />,
    li: ["データ解析回数20回", " データ解析発話数の合計が20発話"],
  },
  {
    title: "少額プラン",
    description: "基本的な機能が使えるお試しプラン",
    price: <PriceItem price={1} unit={"円 / 発話数"} />,
    li: ["CSVダウンロード", "API機能", "複数人対応"],
  },
  {
    title: "年額プラン",
    description: "基本的な機能が使える年額プラン",
    price: <PriceItem price={1000000} unit={"円 / 年"} />,
  },
];

export const Pricing: React.FC = (): ReactElement => {
  const classes = useStyles();
  return (
    <TopPageLayout title={"料金プラン"}>
      <Grid
        className={classes.grid}
        container={true}
        spacing={3}
        justify={"center"}
      >
        {plans.map((plan, index) => (
          <Grid item={true} key={index} xs={10} sm={8} md={3}>
            <CardStyled>
              <CardHeader
                className={classes.cardHeader}
                title={plan.title}
                titleTypographyProps={{ align: "center" }}
              />
              <CardContent>
                <DivPriceWrapper>{plan.price}</DivPriceWrapper>
                <Typography variant="body1">{plan.description}</Typography>
                <ul>
                  {plan.li &&
                    plan.li.map((line, liIndex) => (
                      <Typography
                        key={liIndex}
                        variant={"subtitle1"}
                        component={"li"}
                      >
                        {line}
                      </Typography>
                    ))}
                </ul>
              </CardContent>
            </CardStyled>
          </Grid>
        ))}
      </Grid>
    </TopPageLayout>
  );
};
