import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { RoutingPaths, UserRoutingPaths } from "../CommonTypes";
import { Button, TextField, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { basePathOfApiServer } from "../utils/utility";
import { BaseLayout } from "./BaseLayout";
import { saveAccessToken } from "../utils/storage";

// ログイン用URI
const LOGIN_PATH = "/st-cloud/v2.0/user/login";

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// ログインレスポンス
type LoginResponse = {
  token: string;
  expirationPeriod: number;
};

/**
 * ログインを行う
 *
 * @param id ログインID
 * @param pass ログインパスワード
 */
const postLoginRequest = async (
  id: string,
  pass: string
): Promise<string | null> => {
  try {
    const uri = basePathOfApiServer() + LOGIN_PATH;
    const headers = {
      "Content-Type": "application/json",
    };
    let response = await axios.post(
      uri,
      {
        loginId: id,
        password: pass,
      },
      {
        headers: headers,
      }
    );
    return (response.data as LoginResponse).token;
  } catch (e) {
    console.error("[Login Request Error]", e);
    return null;
  }
};

/**
 * ログインページコンポーネント
 *
 * @constructor
 */
export const LogIn: React.FC = (): ReactElement => {
  const routeHistory = useHistory();
  const classes = useStyles();
  const [failedToLogIn, setFailedToLogIn] = useState(false);

  const [userEmail, setUserEmail] = useState<string>("");
  const [userPassword, setUserPassWord] = useState<string>("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserPassWord(event.target.value);
  };

  return (
    <BaseLayout>
      <form className={classes.form} noValidate>
        <Typography component="h1" variant="h5" align={"center"}>
          ログイン
        </Typography>
        {failedToLogIn && (
          <Typography
            variant={"body1"}
            align={"center"}
            color={"error"}
            className={classes.errorMessage}
          >
            ログインに失敗しました。メールアドレスかパスワードが間違っています。
          </Typography>
        )}
        <TextField
          label={"メールアドレス"}
          variant={"outlined"}
          required={true}
          fullWidth={true}
          margin={"normal"}
          autoComplete={"email"}
          name={"email"}
          autoFocus={true}
          value={userEmail}
          onChange={handleEmailChange}
        />
        <TextField
          label={"パスワード"}
          variant={"outlined"}
          required={true}
          fullWidth={true}
          margin={"normal"}
          type={"password"}
          value={userPassword}
          onChange={handlePasswordChange}
        />
        <Button
          className={classes.submit}
          type={"submit"}
          color={"primary"}
          variant={"contained"}
          fullWidth={true}
          size={"large"}
          data-testid={"logInButtonTest"}
          disabled={!userEmail || !userPassword}
          onClick={(e) => {
            e.preventDefault();
            postLoginRequest(userEmail, userPassword).then((token) => {
              if (token === null) {
                setFailedToLogIn(true);
              } else {
                saveAccessToken(token);
                routeHistory.push(
                  RoutingPaths.userMyPage + UserRoutingPaths.analysis
                );
              }
            });
          }}
        >
          ログイン
        </Button>
        {/* FIXME: 新規登録停止中のためコメントアウト */}
        {/*<Grid container>*/}
        {/*  <Grid item xs>*/}
        {/*    <MuiLink*/}
        {/*      component={Link}*/}
        {/*      to={RoutingPaths.passwordReset + PasswordRestPaths.application}*/}
        {/*    >*/}
        {/*      パスワードを忘れた*/}
        {/*    </MuiLink>*/}
        {/*  </Grid>*/}
        {/*  <Grid item>*/}
        {/*    <MuiLink*/}
        {/*      component={Link}*/}
        {/*      to={RoutingPaths.signUp + SignUpRoutingPaths.input}*/}
        {/*    >*/}
        {/*      新規アカウント作成*/}
        {/*    </MuiLink>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </form>
    </BaseLayout>
  );
};
