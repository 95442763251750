import React, { ReactElement, useMemo, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  LicenseInfo,
  RoutingPaths,
  UserManagementPaths,
  UserRoutingPaths,
} from "../../CommonTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      maxWidth: 700,
      margin: "0 auto",
    },
    tableWrapper: {
      overflowX: "auto",
    },
    tableTitle: {
      margin: theme.spacing(2),
    },
    table1stCol: {
      whiteSpace: "nowrap",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    table2ndCol: {
      whiteSpace: "nowrap",
    },
    tableTokenCell: {
      whiteSpace: "normal",
      wordBreak: "break-word",
    },
    planWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    plan: {
      marginRight: theme.spacing(2),
    },
    link: {
      cursor: "pointer",
    },
  })
);

type TableRowUserInfoProps = {
  label: string;
  value: string | ReactElement;
  valueIsToken?: boolean; // トークン情報化どうか
};

/**
 * ユーザ情報の各行
 *
 * @param props
 * @constructor
 */
const TableRowUserInfo: React.FC<TableRowUserInfoProps> = (
  props: TableRowUserInfoProps
) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.table1stCol}>{props.label}</TableCell>
      <TableCell
        className={
          props.valueIsToken ? classes.tableTokenCell : classes.table2ndCol
        }
      >
        {props.value}
      </TableCell>
    </TableRow>
  );
};

type LicenseType = "free" | "payed";

type PlanDialogProps = {
  currentPlan: LicenseType; // 現在のプラン
  handleClose: () => void; // ダイアログを閉じるハンドラー
};

/**
 * プラン変更ダイアログ
 *
 * @param props
 * @constructor
 */
const PlanDialog: React.FC<PlanDialogProps> = (
  props: PlanDialogProps
): ReactElement => {
  const title =
    props.currentPlan === "free"
      ? "有料プランを契約しますか？"
      : "無料プランに変更しますか？";
  return (
    <Dialog open={true} onClose={props.handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Grid container={true} justify={"space-around"}>
          <Grid item={true}>
            {/* TODO  プラン変更処理の実装 */}
            <Button onClick={() => {}} color={"primary"} size={"small"}>
              はい
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              onClick={props.handleClose}
              color={"primary"}
              size={"small"}
              autoFocus
            >
              いいえ
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

/**
 * ライセンス種別の日本語を取得する
 *
 * @param type
 */
const getLicenseTypeJp = (type: LicenseType | null): string => {
  if (type === null) {
    return "";
  }
  switch (type) {
    case "free":
      return "無料";
    case "payed":
      return "有料";
  }
};

type UserManagementProps = {
  accessToken: string; // ST Cloudのアクセストークン
  licenseInfo?: LicenseInfo; // ユーザのライセンス情報
};

/**
 * ユーザ管理コンポーネント
 *
 * @constructor
 */
export const UserManagement: React.FC<UserManagementProps> = (
  props: UserManagementProps
): ReactElement => {
  const classes = useStyles();
  const [planDialogIsOpen, setPlanDialogIsOpen] = useState(false);

  const createdDate = useMemo(() => {
    if (props.licenseInfo) {
      const date = new Date(props.licenseInfo.createdAt);
      return date.toLocaleString();
    } else {
      return "";
    }
  }, [props.licenseInfo]);

  const licenseType: LicenseType | null = useMemo(() => {
    if (props.licenseInfo) {
      if (props.licenseInfo.licenseType === 1) {
        return "free";
      } else {
        return "payed";
      }
    } else {
      return null;
    }
  }, [props.licenseInfo]);

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Typography
        className={classes.tableTitle}
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
      >
        アカウント情報
      </Typography>
      <div className={classes.tableWrapper}>
        <Table>
          <TableBody>
            <TableRowUserInfo
              label={"メールアドレス"}
              value={props.licenseInfo ? props.licenseInfo.email : ""}
            />
            <TableRowUserInfo
              label={"パスワード"}
              value={
                <MuiLink
                  className={classes.link}
                  component={Link}
                  to={
                    RoutingPaths.userMyPage +
                    UserRoutingPaths.management +
                    UserManagementPaths.changePassword
                  }
                >
                  [パスワードを変更する]
                </MuiLink>
              }
            />
            <TableRowUserInfo
              label={"契約プラン"}
              value={
                <div className={classes.planWrapper}>
                  <span className={classes.plan}>
                    {getLicenseTypeJp(licenseType)}
                  </span>
                  <Button
                    variant={"contained"}
                    size={"small"}
                    disableElevation={true}
                    onClick={() => setPlanDialogIsOpen(true)}
                  >
                    プランを変更する
                  </Button>
                </div>
              }
            />
            <TableRowUserInfo label={"ユーザ作成日"} value={createdDate} />
            <TableRowUserInfo
              label={"アクセストークン"}
              value={props.accessToken}
              valueIsToken={true}
            />
          </TableBody>
        </Table>
      </div>
      {planDialogIsOpen && licenseType !== null && (
        <PlanDialog
          currentPlan={licenseType}
          handleClose={() => setPlanDialogIsOpen(false)}
        />
      )}
    </TableContainer>
  );
};
