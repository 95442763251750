import React, {
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  ChannelId,
  UTTERANCE_FINISH_CODE_OF_RESTRICTION,
} from "../../../CommonTypes";
import { ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN } from "./AnalysisResultStyles";
import {
  AnalysisV20ResultUtteranceList,
  TextEmotionResult,
  VoiceEmotionResultEmotion4ConfEnum,
  VoiceEmotionResultEmotion8ConfEnum,
} from "../../../lib";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import {
  isEmotion8Unknown,
  VOICE_ANALYSIS_FINISH_CODE_NO_VOICE,
  VOICE_ANALYSIS_FINISH_CODE_NOT_VOICE,
} from "../../../utils/utility";

const DEF_FRACTION_DIGITS = 2;
const ANALYSIS_RESTRICTION_TRANSCRIPTION = "【解析制限】";

/**
 * チャンネルIDからチャンネル接頭辞を取得する
 *
 * @param channelId チャンネルID
 */
const getChannelPrefixStr = (channelId: ChannelId): string => {
  if (channelId === ChannelId.monaural) {
    return "";
  } else {
    return "Ch" + String(channelId) + "-";
  }
};

/**
 * 音声の長さの表示を整える(hh:mm:ss.ms)
 *
 * @param audioDuration 音声長（秒）
 * @param milliSecDigit ミリ秒の表示桁数
 * @return
 */
const prepareAudioDuration = (
  audioDuration: number,
  milliSecDigit = DEF_FRACTION_DIGITS
): string => {
  let minutes = Math.floor(audioDuration / 60);
  const seconds = String((audioDuration % 60).toFixed(milliSecDigit)).padStart(
    3 + milliSecDigit,
    "0"
  );
  let preparedAudioDuration;
  if (minutes < 60) {
    preparedAudioDuration = minutes
      ? `${String(minutes).padStart(2, "0")}:${seconds}`
      : `${seconds}`;
  } else {
    const hour = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    preparedAudioDuration = `${hour}:${String(minutes).padStart(
      2,
      "0"
    )}:${seconds}`;
  }
  return preparedAudioDuration;
};

const DivWordsWrapper = styled.div`
  font-size: smaller;
  color: grey;
`;

const SpanWordMargin = styled.span`
  margin-left: 5px;
`;

const InputUtteranceTranscription = styled.input`
  width: 100%;
`;

/**
 * 八感情の日本語表記を取得する
 *
 * @param emo8 確定8感情種別
 * @param finishCode 解析終了コード
 */
const getEmotion8JapaneseLabel = (
  emo8: VoiceEmotionResultEmotion8ConfEnum,
  finishCode: number
): string => {
  switch (emo8) {
    case VoiceEmotionResultEmotion8ConfEnum.Joy1:
      return "嬉しそう";
    case VoiceEmotionResultEmotion8ConfEnum.Joy2:
      return "楽しそう";
    case VoiceEmotionResultEmotion8ConfEnum.Anger1:
      return "怒ってそう";
    case VoiceEmotionResultEmotion8ConfEnum.Anger2:
      return "ムッとしてそう";
    case VoiceEmotionResultEmotion8ConfEnum.Anger3:
      return "嫌そう";
    case VoiceEmotionResultEmotion8ConfEnum.Sorrow1:
      return "沈んでそう";
    case VoiceEmotionResultEmotion8ConfEnum.Sorrow2:
      return "悲しそう";
    case VoiceEmotionResultEmotion8ConfEnum.Calm:
      return "単調";
    case VoiceEmotionResultEmotion8ConfEnum.LowLevel:
      return "不明(低スコア)";
    case VoiceEmotionResultEmotion8ConfEnum.SameLevel:
      return "不明(同一スコア)";
    case VoiceEmotionResultEmotion8ConfEnum.LongUtterance:
      return "不明(発話が長い)";
    case VoiceEmotionResultEmotion8ConfEnum.Fail:
      if (
        finishCode === VOICE_ANALYSIS_FINISH_CODE_NOT_VOICE ||
        finishCode === VOICE_ANALYSIS_FINISH_CODE_NO_VOICE
      ) {
        return "不明(音声以外)";
      } else {
        return "解析エラー";
      }
  }
};

/**
 * 4感情の日本語表記を取得する
 *
 * @param emo4 確定4感情
 * @param finishCode 解析終了コード
 */
const getEmotion4JapaneseLabel = (
  emo4: VoiceEmotionResultEmotion4ConfEnum,
  finishCode: number
): string => {
  switch (emo4) {
    case VoiceEmotionResultEmotion4ConfEnum.Joy:
      return "喜び(4感情)";
    case VoiceEmotionResultEmotion4ConfEnum.Anger:
      return "怒り(4感情)";
    case VoiceEmotionResultEmotion4ConfEnum.Sorrow:
      return "哀しみ(4感情)";
    case VoiceEmotionResultEmotion4ConfEnum.Calm:
      return "単調(4感情)";
    case VoiceEmotionResultEmotion4ConfEnum.LowLevel:
      return "不明(低スコア)";
    case VoiceEmotionResultEmotion4ConfEnum.SameLevel:
      return "不明(同一スコア)";
    case VoiceEmotionResultEmotion4ConfEnum.LongUtterance:
      return "不明(発話が長い)";
    case VoiceEmotionResultEmotion4ConfEnum.SameMergedLevel:
      return "不明(同一4感情スコア)";
    case VoiceEmotionResultEmotion4ConfEnum.Fail:
      if (
        finishCode === VOICE_ANALYSIS_FINISH_CODE_NOT_VOICE ||
        finishCode === VOICE_ANALYSIS_FINISH_CODE_NO_VOICE
      ) {
        return "不明(音声以外)";
      } else {
        return "解析エラー";
      }
  }
};

type SpeechListItemProps = {
  utterance: AnalysisV20ResultUtteranceList; // 発話の解析結果
  audioProgressMilliSec: number; // 音声の再生時間（ミリ秒）
  setUtteranceTranscription: (transcription: string) => void; // 発話内容の更新用コールバック
};

/**
 * 発話一覧の行要素
 *
 * @constructor
 */
const SpeechListItem: React.FC<SpeechListItemProps> = (
  props: SpeechListItemProps
): ReactElement => {
  const channelStr = getChannelPrefixStr(props.utterance.channelId);
  const utteranceIdStr =
    channelStr + props.utterance.utteranceId.toString(10).padStart(3, "0");

  let words = <DivWordsWrapper />;
  let transcription = "";
  let semanticStr = "";
  let emotionStr = "";
  let emotionConf = "";
  if (
    props.utterance.voiceEmotion.finishCode ===
    UTTERANCE_FINISH_CODE_OF_RESTRICTION
  ) {
    transcription = ANALYSIS_RESTRICTION_TRANSCRIPTION;
  } else {
    if (props.utterance.textEmotion) {
      if (props.utterance.textEmotion.wordsByGoogleStt) {
        words = (
          <DivWordsWrapper>
            {props.utterance.textEmotion.wordsByGoogleStt.map(
              (wordInfo, index) => {
                const startMilliSec =
                  props.utterance.startMilliSecond +
                  parseFloat(wordInfo.startTime) * 1000;
                const endMilliSec =
                  props.utterance.startMilliSecond +
                  parseFloat(wordInfo.endTime) * 1000;
                const style =
                  props.audioProgressMilliSec >= startMilliSec &&
                  props.audioProgressMilliSec <= endMilliSec
                    ? { backgroundColor: "pink" }
                    : undefined;
                return (
                  <React.Fragment key={index}>
                    <span style={style}>
                      {wordInfo.word.split("|").shift()}
                    </span>
                    <SpanWordMargin />
                  </React.Fragment>
                );
              }
            )}
          </DivWordsWrapper>
        );
      }
      transcription = props.utterance.textEmotion.transcription;
      semanticStr = props.utterance.textEmotion.semanticFeatures
        .map((feature) => feature.type)
        .join(",");
      emotionStr = props.utterance.textEmotion.emotionFeatures
        .map((feature) => feature.emotionType)
        .join(",");
    }
    if (!isEmotion8Unknown(props.utterance.voiceEmotion.emotion8Conf)) {
      emotionConf = getEmotion8JapaneseLabel(
        props.utterance.voiceEmotion.emotion8Conf,
        props.utterance.voiceEmotion.finishCode
      );
    } else {
      emotionConf = getEmotion4JapaneseLabel(
        props.utterance.voiceEmotion.emotion4Conf,
        props.utterance.voiceEmotion.finishCode
      );
    }
  }

  const regionStr = `${prepareAudioDuration(
    props.utterance.startMilliSecond / 1000
  )} - ${prepareAudioDuration(props.utterance.endMilliSecond / 1000)}`;
  const durationStr = (
    (props.utterance.endMilliSecond - props.utterance.startMilliSecond) /
    1000
  ).toFixed(DEF_FRACTION_DIGITS);
  return (
    <TableRow>
      <TableCell>{utteranceIdStr}</TableCell>
      <TableCell>
        {words}
        <InputUtteranceTranscription
          data-testid={"test-transcription"}
          type={"text"}
          value={transcription}
          onChange={(event) =>
            props.setUtteranceTranscription(event.target.value)
          }
        />
      </TableCell>
      <TableCell>{emotionStr}</TableCell>
      <TableCell>{semanticStr}</TableCell>
      <TableCell>{emotionConf}</TableCell>
      <TableCell>{regionStr}</TableCell>
      <TableCell>{durationStr}</TableCell>
    </TableRow>
  );
};

const DivSpeechList = styled.div`
  display: block;
  padding: 0 ${ANALYSIS_RESULT_CONTENT_LEFT_AND_RIGHT_MARGIN}px;
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border: 1px dimgray solid;
    }

    thead {
      background-color: silver;
      text-align: center;

      th:nth-child(1),
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(6) {
        width: 90px;
      }
    }

    tbody {
      td:nth-child(1),
      td:nth-child(4),
      td:nth-child(5) {
        text-align: center;
      }

      td:nth-child(2),
      td:nth-child(3) {
        text-align: left;
      }

      td:nth-child(6) {
        text-align: right;
      }

      tr:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

/**
 * 書き起こし文を更新する
 *
 * @param utteranceList もとの発話一覧
 * @param newTranscription 更新する書き起こし文
 * @param index 発話のインデックス
 */
const updateTranscription = (
  utteranceList: AnalysisV20ResultUtteranceList[],
  newTranscription: string,
  index: number
): AnalysisV20ResultUtteranceList[] => {
  const oldUtterance = utteranceList[index];
  let newTextEmotion: TextEmotionResult;
  if (oldUtterance.textEmotion !== undefined) {
    newTextEmotion = {
      ...oldUtterance.textEmotion,
      transcription: newTranscription,
    };
  } else {
    newTextEmotion = {
      transcription: newTranscription,
      pronunciation: "",
      emotionFeatures: [],
      semanticFeatures: [],
      wordFeatures: [],
      wordsByGoogleStt: [],
      finishCode: 0,
    };
  }
  const newList = utteranceList.concat();
  newList[index] = {
    ...oldUtterance,
    textEmotion: newTextEmotion,
  };
  return newList;
};

type Order = "asc" | "desc";
type EnableSortId = "no" | "section";
type TableColumnId =
  | "no"
  | "content"
  | "emotionWords"
  | "semanticTypes"
  | "st"
  | "section"
  | "duration";

/**
 * 降順
 *
 * @param a １つ目の要素
 * @param b 2つ目の要素
 * @param orderBy 比較するID
 */
const descendingComparator = (
  a: AnalysisV20ResultUtteranceList,
  b: AnalysisV20ResultUtteranceList,
  orderBy: TableColumnId
) => {
  switch (orderBy) {
    case "no":
      if (b.channelId < a.channelId) {
        return -1;
      }
      if (b.channelId > a.channelId) {
        return 1;
      }
      if (b.utteranceId < a.utteranceId) {
        return -1;
      }
      if (b.utteranceId > a.utteranceId) {
        return 1;
      }
      return 0;
    case "section":
      if (b.startMilliSecond < a.startMilliSecond) {
        return -1;
      }
      if (b.startMilliSecond > a.startMilliSecond) {
        return 1;
      }
      return 0;
    default:
      return 0;
  }
};

/**
 * ソート用関数を作成する
 *
 * @param order 降順か昇順か
 * @param orderBy ソート元のID
 */
const getComparator = (
  order: Order,
  orderBy: TableColumnId
): ((
  a: AnalysisV20ResultUtteranceList,
  b: AnalysisV20ResultUtteranceList
) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

/**
 * ソートする
 *
 * @param list 表示する列一覧
 * @param comparator ソート関数
 */
const stableSort = (
  list: AnalysisV20ResultUtteranceList[],
  comparator: (
    a: AnalysisV20ResultUtteranceList,
    b: AnalysisV20ResultUtteranceList
  ) => number
): AnalysisV20ResultUtteranceList[] => {
  const stabilizedThis = list.map(
    (utterance, idx) =>
      [utterance, idx] as [AnalysisV20ResultUtteranceList, number]
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

type HeadCell = {
  id: TableColumnId;
  label: string;
};

const headCells: HeadCell[] = [
  { id: "no", label: "発話番号" },
  { id: "content", label: "発話内容" },
  { id: "emotionWords", label: "感情語彙種別" },
  { id: "semanticTypes", label: "文章意味種別" },
  { id: "st", label: "音声感情認識" },
  { id: "section", label: "発話区間" },
  { id: "duration", label: "発話長" },
];

type SpeechListTabViewProps = {
  utterances: AnalysisV20ResultUtteranceList[]; // 解析結果の発話一覧
  audio: RefObject<HTMLAudioElement>; // 音声要素
  setUtteranceList: (newList: AnalysisV20ResultUtteranceList[]) => void; // 発話一覧を更新する
};

/**
 * 音声認識一覧を描画
 *
 * @constructor
 */
export const SpeechListTabView: React.FC<SpeechListTabViewProps> = (
  props: SpeechListTabViewProps
): ReactElement => {
  // 音声再生位置更新
  const [currentAudioMilliSec, setCurrentAudioMilliSec] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<EnableSortId>("no");
  const progressIdRef = useRef<number>();
  useEffect(() => {
    const progress = () => {
      if (props.audio.current) {
        setCurrentAudioMilliSec(props.audio.current.currentTime * 1000);
      }
      progressIdRef.current = requestAnimationFrame(progress);
    };
    progressIdRef.current = requestAnimationFrame(progress);
    return () =>
      progressIdRef.current !== undefined
        ? cancelAnimationFrame(progressIdRef.current)
        : undefined;
  }, [props.audio]);

  const handleSort = (id: TableColumnId) => {
    if (id === "no" || id === "section") {
      const isAsc = id === orderBy && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  return (
    <DivSpeechList>
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    hideSortIcon={
                      !(headCell.id === "no" || headCell.id === "section")
                    }
                    onClick={() => handleSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(props.utterances, getComparator(order, orderBy)).map(
              (utterance, index) => (
                <SpeechListItem
                  key={index}
                  utterance={utterance}
                  audioProgressMilliSec={currentAudioMilliSec}
                  setUtteranceTranscription={(newTranscription: string) => {
                    const newList = updateTranscription(
                      props.utterances,
                      newTranscription,
                      index
                    );
                    props.setUtteranceList(newList);
                  }}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DivSpeechList>
  );
};
