import { Emotion4Color, UtteranceColor } from "../CommonTypes";
import {
  AnalysisV20Result,
  AudioInfoAudioDataTypeEnum,
  VoiceEmotionResultEmotion4ConfEnum,
  VoiceEmotionResultEmotion8ConfEnum,
} from "../lib";

// ST Cloudのパスワードの最低長
export const MIN_ST_CLOUD_PASSWORD_LENGTH = 10;
// ST Cloudのパスワードの最大長
export const MAX_ST_CLOUD_PASSWORD_LENGTH = 32;
// ST Cloudの感情解析で声が入っていない場合の終了コード
export const VOICE_ANALYSIS_FINISH_CODE_NO_VOICE = 4;
// ST Cloudの感情解析で声ではない場合の終了コード
export const VOICE_ANALYSIS_FINISH_CODE_NOT_VOICE = 5;
/**
 * パスワードが有効な長さか調べる
 *
 * @param password パスワード
 */
export const isPasswordValidLength = (password: string) => {
  return (
    password.length >= MIN_ST_CLOUD_PASSWORD_LENGTH &&
    password.length <= MAX_ST_CLOUD_PASSWORD_LENGTH
  );
};

/**
 * 8感情が不明かを調べる
 *
 * @param emotion8
 */
export const isEmotion8Unknown = (
  emotion8: VoiceEmotionResultEmotion8ConfEnum
): boolean => {
  return (
    emotion8 === VoiceEmotionResultEmotion8ConfEnum.SameLevel ||
    emotion8 === VoiceEmotionResultEmotion8ConfEnum.LowLevel ||
    emotion8 === VoiceEmotionResultEmotion8ConfEnum.LongUtterance
  );
};

/**
 * 8感情種別から色を取得する
 *
 * @param emotion 8感情種別
 * @param finishCode 感情解析終了コード
 * @param restriction 解析プランによる解析制限
 */
export const getUtteranceColorFromEmo8 = (
  emotion: VoiceEmotionResultEmotion8ConfEnum,
  finishCode: number,
  restriction = false
): string => {
  if (restriction) {
    return UtteranceColor.restriction;
  }

  switch (emotion) {
    case VoiceEmotionResultEmotion8ConfEnum.Joy1:
      return UtteranceColor.joy1;
    case VoiceEmotionResultEmotion8ConfEnum.Joy2:
      return UtteranceColor.joy2;
    case VoiceEmotionResultEmotion8ConfEnum.Anger1:
      return UtteranceColor.anger1;
    case VoiceEmotionResultEmotion8ConfEnum.Anger2:
      return UtteranceColor.anger2;
    case VoiceEmotionResultEmotion8ConfEnum.Anger3:
      return UtteranceColor.anger3;
    case VoiceEmotionResultEmotion8ConfEnum.Sorrow1:
      return UtteranceColor.sorrow1;
    case VoiceEmotionResultEmotion8ConfEnum.Sorrow2:
      return UtteranceColor.sorrow2;
    case VoiceEmotionResultEmotion8ConfEnum.Calm:
      return UtteranceColor.calm;
    case VoiceEmotionResultEmotion8ConfEnum.LowLevel:
      return UtteranceColor.lowLevel;
    case VoiceEmotionResultEmotion8ConfEnum.SameLevel:
      return UtteranceColor.sameLevel;
    case VoiceEmotionResultEmotion8ConfEnum.LongUtterance:
      return UtteranceColor.longUtterance;
    case VoiceEmotionResultEmotion8ConfEnum.Fail:
      if (
        finishCode === VOICE_ANALYSIS_FINISH_CODE_NOT_VOICE ||
        finishCode === VOICE_ANALYSIS_FINISH_CODE_NO_VOICE
      ) {
        return UtteranceColor.notVoice;
      } else {
        return UtteranceColor.error;
      }
  }
};

/**
 * 4感情種別から色を取得する
 *
 * @param emotion 4感情種別
 * @param finishCode 感情解析終了コード
 * @param restriction 解析プランによる解析制限
 */
export const getUtteranceColorFromEmo4 = (
  emotion: VoiceEmotionResultEmotion4ConfEnum,
  finishCode: number,
  restriction = false
): string => {
  if (restriction) {
    return UtteranceColor.restriction;
  }

  switch (emotion) {
    case VoiceEmotionResultEmotion4ConfEnum.Joy:
      return Emotion4Color.joy;
    case VoiceEmotionResultEmotion4ConfEnum.Anger:
      return Emotion4Color.anger;
    case VoiceEmotionResultEmotion4ConfEnum.Sorrow:
      return Emotion4Color.sorrow;
    case VoiceEmotionResultEmotion4ConfEnum.Calm:
      return Emotion4Color.calm;
    case VoiceEmotionResultEmotion4ConfEnum.LowLevel:
      return UtteranceColor.lowLevel;
    case VoiceEmotionResultEmotion4ConfEnum.SameLevel:
      return UtteranceColor.sameLevel;
    case VoiceEmotionResultEmotion4ConfEnum.LongUtterance:
      return UtteranceColor.longUtterance;
    case VoiceEmotionResultEmotion4ConfEnum.SameMergedLevel:
      return UtteranceColor.sameMergedLevel;
    case VoiceEmotionResultEmotion4ConfEnum.Fail:
      if (
        finishCode === VOICE_ANALYSIS_FINISH_CODE_NOT_VOICE ||
        finishCode === VOICE_ANALYSIS_FINISH_CODE_NO_VOICE
      ) {
        return UtteranceColor.notVoice;
      } else {
        return UtteranceColor.error;
      }
  }
};

/**
 * APIの基本パスを取得する
 */
export const basePathOfApiServer = (): string => {
  switch (document.domain) {
    case "localhost":
      return "http://localhost:3010";
    case "dev.www.st-emotion.jp":
      return "https://dev.st-emotion.jp";
    default:
      return "https://st-emotion.jp";
  }
};

const CSV_HEADER = `channelId,utteranceId,startMilliSecond,endMilliSecond,emotion8,emotion8Level,emotion8Conf,joy1Level,joy2Level,anger1Level,anger2Level,anger3Level,sorrow1Level,sorrow2Level,calmLevel,emotion4,emotion4Conf,posiNegaNeu,suffixIntonation,suffixIntonationSlope,voiceEmotionFinishCode,transcription,pronunciation,emotionFeatures,semanticFeatures,wordFeatures,textEmotionFinishCode\n`;
/**
 * 解析結果をCSVに変換する
 *
 * @param result 解析結果
 */
export const convertAnalysisResultToCsv = (
  result: AnalysisV20Result
): string => {
  let csv = CSV_HEADER;
  for (const utterance of result.utteranceList) {
    const voiceEmotion = utterance.voiceEmotion;
    const emotion8LevelDetail = voiceEmotion.emotion8LevelDetail;
    const textEmotion = utterance.textEmotion;

    const escapeCsvValue = (val: string): string =>
      `"${val.replace(/"/g, '""')}"`;
    const row = [
      utterance.channelId.toString(),
      utterance.utteranceId.toString(),
      utterance.startMilliSecond.toString(),
      utterance.endMilliSecond.toString(),
      voiceEmotion.emotion8,
      voiceEmotion.emotion8Level.toString(),
      voiceEmotion.emotion8Conf,
      emotion8LevelDetail.joy1Level,
      emotion8LevelDetail.joy2Level,
      emotion8LevelDetail.anger1Level,
      emotion8LevelDetail.anger2Level,
      emotion8LevelDetail.anger3Level,
      emotion8LevelDetail.sorrow1Level,
      emotion8LevelDetail.sorrow2Level,
      emotion8LevelDetail.calmLevel,
      voiceEmotion.emotion4,
      voiceEmotion.emotion4Conf,
      voiceEmotion.posiNegaNeu,
      voiceEmotion.suffixIntonation,
      voiceEmotion.suffixIntonationSlope.toString(),
      voiceEmotion.finishCode,
      textEmotion ? escapeCsvValue(textEmotion.transcription) : undefined,
      textEmotion ? escapeCsvValue(textEmotion.pronunciation) : undefined,
      textEmotion
        ? escapeCsvValue(JSON.stringify(textEmotion.emotionFeatures))
        : undefined,
      textEmotion
        ? escapeCsvValue(JSON.stringify(textEmotion.semanticFeatures))
        : undefined,
      textEmotion
        ? escapeCsvValue(JSON.stringify(textEmotion.wordFeatures))
        : undefined,
      textEmotion ? textEmotion.finishCode : undefined,
    ];
    csv += row.join(",") + "\n";
  }
  return csv;
};

// mac対応用定義
declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

/**
 * 音声ファイルを読み込む
 *
 * @param file 読み込むファイル
 */
export const readAudioFileData = (file: File): Promise<AudioBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const target = event.target;
      if (
        target !== null &&
        target.result !== null &&
        typeof target.result !== "string"
      ) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioCtx = new AudioContext();
        audioCtx.decodeAudioData(
          target.result,
          (buffer) => {
            resolve(buffer);
          },
          (error) => {
            console.error("[Read Audio Error] Decoding error.", error);
            reject(error);
          }
        );
      } else {
        console.error("[Read Audio Error] Failed to read file.", event);
        reject();
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

/**
 * ファイルの拡張子から対応する音声データ種別を取得する
 * 対応していないファイルが入力された場合はnullが返る
 *
 * @param file 解析対象のファイル
 */
export const getAudioType = (file: File): AudioInfoAudioDataTypeEnum | null => {
  const extension = file.name.split(".").pop();
  if (!extension) {
    return null;
  }
  switch (extension.toUpperCase()) {
    case "WAV":
      return AudioInfoAudioDataTypeEnum.Wav;
    case "MP3":
      return AudioInfoAudioDataTypeEnum.Mp3;
    case "OGG":
      return AudioInfoAudioDataTypeEnum.Ogg;
    case "FLAC":
      return AudioInfoAudioDataTypeEnum.Flac;
    case "AAC":
      return AudioInfoAudioDataTypeEnum.Aac;
    case "M4A":
      return AudioInfoAudioDataTypeEnum.M4a;
    case "MP4":
      return AudioInfoAudioDataTypeEnum.Mp4;
    case "MOV":
      return AudioInfoAudioDataTypeEnum.Mov;
    default:
      return null;
  }
};
