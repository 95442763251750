import React, { ReactElement } from "react";
import { createStyles, IconButton, Theme, Typography } from "@material-ui/core";
import {
  LOGO_SRC_PATH,
  RoutingPaths,
  WEB_DESCRIPTION_PHRASE,
  WEB_SUB_DESCRIPTION_PHRASE,
} from "../CommonTypes";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const DivLogAndDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const ImgLogo = styled.img`
  margin-bottom: 8px;
  width: 200px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
  })
);

/**
 * ロゴと説明文のセット
 *
 * @constructor
 */
export const LogoAndDescriptionPhrase: React.FC = (): ReactElement => {
  const classes = useStyles();

  return (
    <IconButton component={Link} to={RoutingPaths.top} className={classes.link}>
      <DivLogAndDesc>
        <ImgLogo src={LOGO_SRC_PATH} alt={"logo"} />
        <Typography variant="body1" noWrap align={"center"}>
          {WEB_DESCRIPTION_PHRASE}
        </Typography>
        <Typography variant="body2" noWrap align={"center"}>
          {WEB_SUB_DESCRIPTION_PHRASE}
        </Typography>
      </DivLogAndDesc>
    </IconButton>
  );
};
