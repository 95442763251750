import React, { ReactElement } from "react";
import { BaseLayout } from "../BaseLayout";
import { Button, Theme, Typography } from "@material-ui/core";
import { RoutingPaths } from "../../CommonTypes";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * 新規パスワード設定済み画面
 *
 * @constructor
 */
export const ChangedPassword: React.FC = (): ReactElement => {
  const routeHistory = useHistory();
  const classes = useStyles();

  return (
    <BaseLayout>
      <Typography component="h1" variant="h5" align={"center"}>
        パスワード再設定
      </Typography>
      <p />
      <Typography variant="body1">パスワードを再設定しました。</Typography>
      <p />
      <Typography variant="body1">
        新しく設定したパスワードでログインしてください。
      </Typography>
      <Button
        className={classes.button}
        type={"submit"}
        color={"primary"}
        variant={"contained"}
        fullWidth={true}
        size={"large"}
        onClick={() => {
          routeHistory.push(RoutingPaths.login);
        }}
      >
        ログイン画面へ
      </Button>
    </BaseLayout>
  );
};
