import React, { ReactElement } from "react";
import { BaseLayout } from "../BaseLayout";
import { Typography } from "@material-ui/core";

export const SentResetEmail: React.FC = (): ReactElement => {
  return (
    <BaseLayout>
      <Typography component="h1" variant="h5" align={"center"}>
        メール送信
      </Typography>
      <p />
      <Typography variant="body1">
        パスワード再設定用のメールを送信しました。
      </Typography>
      <p />
      <Typography variant="body1">
        メール内のURLをクリックして、パスワード再設定画面を開いてください。
      </Typography>
    </BaseLayout>
  );
};
