import React, { ReactElement } from "react";
import {
  Button,
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandMore, AnnouncementOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      alignItems: "baseline",
    },
    title: {
      marginRight: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    headingResetButton: {
      whiteSpace: "nowrap",
      margin: "0 0 0 auto",
    },
  })
);

type AnalysisConfigExpansionPanelProps = {
  title: string; // 表示タイトル
  resetCallback: () => void; // リセットボタンが押された際のコールバック
  isValueChanged: boolean; // 設定値が変更されているか
  children?: ReactElement;
};

/**
 * 解析設定オプションのパネルコンポーネント
 *
 * @param props
 * @constructor
 */
export const AnalysisConfigExpansionPanel: React.FC<AnalysisConfigExpansionPanelProps> = (
  props: AnalysisConfigExpansionPanelProps
): ReactElement => {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        classes={{ content: classes.header }}
        expandIcon={<ExpandMore />}
      >
        <Typography className={classes.title}>{props.title}</Typography>
        {props.isValueChanged && (
          <Tooltip title={"設定が変更されています"} placement={"right"}>
            <AnnouncementOutlined fontSize={"inherit"} />
          </Tooltip>
        )}
        <Button
          className={classes.headingResetButton}
          variant={"contained"}
          size={"small"}
          disableElevation={true}
          data-testid={"resetButton"}
          onClick={(e) => {
            e.stopPropagation();
            props.resetCallback();
          }}
        >
          リセット
        </Button>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};
